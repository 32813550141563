import React from 'react';

type RocketProps = {
  size?: number;
};

const Rocket: React.FC<RocketProps> = ({ size = 32 }) => (
  <svg width={size} height={size} viewBox="0 0 30 30" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.98848 18.8597L11.1646 18.7962L11.1016 20.9862C11.0385 24.5409 8.10541 27.4292 4.57312 27.5561L2.39697 27.6196L2.46005 25.4296C2.55466 21.8749 5.45619 18.9232 8.98848 18.8597Z"
      fill="#0083FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.38924 20.0658L9.93462 20.0023L9.87154 21.5257C9.80846 24.0648 7.6954 26.1913 5.17233 26.2548L3.62695 26.3183L3.69003 24.7948C3.72157 22.224 5.86617 20.0975 8.38924 20.0658Z"
      fill="#F1F1F1"
    />
    <mask id="RocketMask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="5" width="25" height="25">
      <path fillRule="evenodd" clipRule="evenodd" d="M24.5053 5.5294H0V30H24.5053V5.5294Z" fill="white" />
    </mask>
    <g mask="url(#RocketMask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M-0.000395263 18.162C0.314988 17.5907 0.756525 17.1146 1.19806 16.6703C2.33344 15.5594 3.68959 14.7977 5.20343 14.4168C8.01034 13.7185 11.1326 14.4803 13.3088 16.6703C15.4849 18.8602 16.2418 21.9706 15.548 24.7954C15.1695 26.2236 14.4126 27.6519 13.3088 28.7945C12.8672 29.2388 12.2996 29.6832 11.8265 29.9688C15.0749 30.0958 18.4495 28.9214 20.9411 26.4141C22.928 24.4145 24.0634 21.9706 24.4418 19.4315C24.9464 15.7498 23.748 11.9412 20.9411 9.14816C18.1341 6.35515 14.2549 5.14908 10.6596 5.6569C8.10496 6.03776 5.61343 7.14862 3.65805 9.14816C1.07191 11.5603 -0.126549 14.9246 -0.000395263 18.162Z"
        fill="#0083FF"
      />
    </g>
    <mask id="RocketMask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="5" y="0" width="25" height="25">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M29.9615 0.0068512H5.36157V24.6044H29.9615V0.0068512Z"
        fill="white"
      />
    </mask>
    <g mask="url(#RocketMask1)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.36157 18.2249C7.12772 7.56068 16.2738 -0.31054 27.6276 0.00684753L29.8668 0.0703251L29.9615 2.32378C30.2453 13.6863 22.3923 22.8588 11.7323 24.6044L5.36157 18.2249Z"
        fill="#F1F1F1"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.5892 23.2396C15.0123 23.8744 13.4038 24.3188 11.7008 24.6361L5.36157 18.2566C5.61388 16.5745 6.11849 14.9241 6.74926 13.3689L16.5892 23.2396Z"
      fill="#0083FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.2161 1.02249C22.5184 0.324236 25.0099 -0.0566294 27.6276 0.00684816L29.8668 0.0703257L29.9614 2.32378C30.0245 4.9581 29.646 7.43372 28.9522 9.75066L20.2161 1.02249Z"
      fill="#0083FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.7322 24.6044C11.1014 24.7314 10.4076 24.7948 9.80838 24.8583L5.17224 20.2245C5.23532 19.5262 5.29839 18.9232 5.42455 18.2884L11.7322 24.6044Z"
      fill="#F1F1F1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.9284 7.05286C21.0676 5.18028 18.0084 5.18028 16.1476 7.05286C14.2869 8.92545 14.2869 11.9724 16.1476 13.845C18.0084 15.7176 21.0676 15.7176 22.9284 13.845C24.8207 11.9724 24.8207 8.92545 22.9284 7.05286Z"
      fill="#68B6FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.9192 8.0685C20.5946 6.76721 18.4815 6.76721 17.1569 8.0685C15.8323 9.36979 15.8323 11.528 17.1569 12.8293C18.4815 14.1306 20.5946 14.1306 21.9192 12.8293C23.2438 11.528 23.2438 9.40153 21.9192 8.0685Z"
      fill="#F1F1F1"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.6668 8.83023C20.4684 7.6559 18.6076 7.6559 17.4407 8.83023C16.3053 9.94109 16.2423 11.7502 17.3146 12.9563C17.2515 12.8928 17.1884 12.8928 17.1884 12.8293C15.8638 11.528 15.8638 9.40153 17.1884 8.10024C18.513 6.79895 20.6261 6.79895 21.9192 8.10024C23.2438 9.40153 23.2438 11.528 21.9192 12.8293C21.8561 12.8928 21.793 12.8928 21.793 12.9563C22.7707 11.7502 22.7707 9.94109 21.6668 8.83023Z"
      fill="#0083FF"
    />
  </svg>
);

export default Rocket;
