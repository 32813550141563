import React from 'react';

type PaperPlaneProps = {
  size?: number;
};

const PaperPlane: React.FC<PaperPlaneProps> = ({ size = 32 }) => (
  <svg width={size} height={size} viewBox="0 0 30 20" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 6.2704L8.81443 10.9655L30 0L0 6.2704ZM12.433 13.035L22.7629 19.7996L30 0L12.433 13.035Z"
      fill="#D1E9FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.81445 10.9655L10.3918 19.8305L12.433 13.035L30 0L8.81445 10.9655Z"
      fill="#0083FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.3608 19.8305L16.4846 15.7223L12.433 13.0659L10.3608 19.8305Z"
      fill="#68B6FF"
    />
  </svg>
);

export default PaperPlane;
