import React from 'react';
import styled, { keyframes } from 'styled-components';

import { Colors, Spacing } from '../../../styles/constants';

import { Horizontal, Vertical } from '../../Layout/Containers';
import { Label } from '../../Layout/Text';
import ContentCard, { ContentCardType } from '../Card';

type ContentCardListContainerType = {
  position: number;
};

const SlideAndFadeIn = keyframes`
  0% {
    margin-top: 200px;
    opacity: 0;
  }
  100% {
    margin-top: 0;
    opacity: 1;
  }
`;

const ContentCardListContainer = styled(Vertical)<ContentCardListContainerType>`
  padding: ${Spacing.m}px 0;
  animation: 500ms ease-in-out ${({ position }) => (position - 1) * 300}ms both ${SlideAndFadeIn};

  & > ${Label} {
    margin: 0 ${Spacing.xl}px;
  }

  &:nth-child(even) {
    background-color: ${Colors.White.Regular};
  }

  &:nth-child(odd) {
    background-color: ${Colors.Blue.Lighter};
  }
`;

const HorizontallyScrollableList = styled(Horizontal)`
  overflow-y: auto;
  scroll-snap-type: x mandatory;
  scroll-padding: 32px;

  &::before,
  &::after {
    content: '';
    flex: 0 0 ${Spacing.xl}px;
  }
`;

type ContentCardListType = {
  listTitle: string;
  listIndex: number;
  contentCardList: Array<ContentCardType>;
};

const ContentCardList: React.FC<ContentCardListType> = ({ listTitle, contentCardList, listIndex }) => {
  return (
    <ContentCardListContainer position={listIndex}>
      <Label align="left">{listTitle}</Label>
      <HorizontallyScrollableList>
        {contentCardList.map((contentCard, index) => (
          <ContentCard {...contentCard} key={contentCard.id} listIndex={listIndex} cardIndex={index + 1} />
        ))}
      </HorizontallyScrollableList>
    </ContentCardListContainer>
  );
};

export default ContentCardList;
