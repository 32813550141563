import React from 'react';

type WavingHandProps = {
  size?: number;
};

const WavingHand: React.FC<WavingHandProps> = ({ size = 32 }) => (
  <svg width={size} height={size} viewBox="0 0 45 45" fill="none">
    <g clipPath="url(#WavingHandClipPath)">
      <path
        d="M36.9577 15.9528L35.0319 29.916C34.6377 32.3616 33.2881 34.5501 31.2805 36.0003L29.7901 37.0769C25.958 39.8451 20.6067 38.9827 17.8383 35.1503C16.5042 33.3035 14.7078 31.8411 12.6295 30.9087L7.31587 28.5254C6.50245 28.1607 6.03438 27.3326 6.09531 26.4847C6.19612 25.0594 7.68916 24.1446 9.01464 24.7383C16.7477 28.2068 7.7927 24.19 16.5816 28.1323C6.64088 20.0373 6.86524 20.3233 6.56676 19.7104C6.52735 19.6558 6.39955 19.3344 6.35735 19.0761C5.98257 16.9252 8.4716 15.5068 10.1304 16.857C10.6487 17.2791 18.7961 23.9141 19.2959 24.3212C11.0155 12.8582 11.4259 13.4438 11.3221 13.2505C10.7436 12.1734 11.1191 10.8875 11.9109 10.3156C12.9687 9.47008 14.4915 9.69618 15.2658 10.7681L23.3164 21.913C18.5081 8.59087 18.8046 9.5544 18.7809 9.02905C18.7097 7.89342 19.5867 6.76173 20.8338 6.61811C21.7929 6.46301 22.8933 7.04896 23.296 8.10517L27.9688 20.3795L29.5838 22.6153C30.1253 23.365 31.3023 23.086 31.4494 22.1733C31.8659 17.3901 31.8233 17.81 31.8629 17.6082C32.2981 15.4371 33.9587 13.9645 34.7647 13.8328C35.9909 13.5592 37.1756 14.601 36.9577 15.9528Z"
        fill="#0083FF"
      />
      <path
        d="M36.1464 14.0843L33.8887 29.9658C33.4752 31.7821 32.5297 33.4291 31.1788 34.6977C26.9239 38.7003 20.9668 37.7091 18.0718 33.7015C16.7378 31.8547 14.9414 30.3923 12.8631 29.46C5.76712 26.3447 6.18128 26.5287 6.09535 26.4846C6.15156 25.6905 6.66223 24.9781 7.44037 24.6874C7.87735 24.5241 8.20653 24.5397 8.6148 24.6055C12.5629 26.3073 16.5869 28.1285 16.5817 28.1323C17.0326 27.8066 17.0518 27.1418 16.6205 26.7905C7.33937 19.2327 7.87857 19.7296 7.60354 19.3127C7.19049 18.7104 7.08112 17.8303 7.40763 17.0934C7.54396 16.7833 7.75416 16.521 7.77556 16.5052C8.53963 16.1941 9.44554 16.2997 10.1305 16.8569C11.0441 17.601 18.3083 23.5168 19.296 24.3211C10.7217 12.4513 11.4056 13.4248 11.2684 13.1461C11.1142 12.8251 11.0404 12.4714 11.039 12.1806C11.0302 11.8256 11.124 11.4944 11.1293 11.4905C11.6975 9.57422 14.1535 9.2281 15.2659 10.7681L23.3164 21.9129C18.6518 8.98882 18.8941 9.67095 18.8692 9.57646C18.3948 7.9755 19.7828 6.29235 21.5899 6.6503C21.7075 6.67678 21.2333 5.54503 27.2416 21.1448L28.8567 23.3806C29.3213 24.0238 30.4878 24.1263 31.0839 23.2045C31.4842 22.5851 31.4723 21.9108 31.5422 21.1079C31.8571 17.4956 31.8164 17.9231 31.8485 17.6863C32.0171 16.741 32.4429 15.7931 33.1397 14.9954C33.4338 14.6603 33.9392 14.1866 34.3177 13.9925C34.8319 13.7295 35.4154 13.7336 35.9021 13.952C36.0341 14.0089 36.1401 14.0809 36.1464 14.0843Z"
        fill="#68B6FF"
      />
      <path
        d="M29.0741 34.3139C28.8418 34.4817 28.5175 34.4294 28.3497 34.1971C25.9231 30.8378 26.3592 26.1593 29.3642 23.3146C29.5733 23.1175 29.9007 23.1266 30.0976 23.3347C30.2946 23.5428 30.2856 23.8711 30.0775 24.0681C27.4486 26.5567 27.0674 30.6501 29.1908 33.5896C29.3586 33.8218 29.3064 34.1461 29.0741 34.3139Z"
        fill="#0083FF"
      />
    </g>
    <path
      d="M26.6125 4.81506C30.8646 3.99308 34.3665 8.06645 32.5657 11.7398"
      stroke="#0068CA"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.167 8.23096C27.7042 8.23096 28.886 8.44583 29.3157 9.30531C29.7454 10.1648 29.4948 11.0959 29.3157 11.454"
      stroke="#0068CA"
      strokeLinecap="round"
    />
    <defs>
      <clipPath id="WavingHandClipPath">
        <rect width="32.2306" height="32.2306" fill="white" transform="translate(0 18.873) rotate(-35.8427)" />
      </clipPath>
    </defs>
  </svg>
);

export default WavingHand;
