import React from 'react';

type PlaneProps = {
  size?: number;
};

const Plane: React.FC<PlaneProps> = ({ size = 32 }) => (
  <svg width={size} height={size} viewBox="0 0 30 30" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M25.3554 19.8837C25.0341 20.2326 24.4791 20.2326 24.1578 19.8837L22.9601 18.6628C22.6388 18.314 22.6388 17.7907 22.9601 17.4419L25.3846 15.0291C25.706 14.6802 26.261 14.6802 26.5823 15.0291L27.78 16.25C28.1305 16.5698 28.1305 17.1221 27.78 17.4709L25.3554 19.8837Z"
      fill="#0083FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5316 7.03488C12.2103 7.38372 11.6553 7.38372 11.334 7.03488L10.1071 5.81395C9.78576 5.49418 9.78576 4.94186 10.1071 4.59302L12.5316 2.18023C12.8529 1.83139 13.408 1.83139 13.7293 2.18023L14.9269 3.40116C15.2483 3.75 15.2483 4.27325 14.9269 4.62209L12.5316 7.03488Z"
      fill="#0083FF"
    />
    <mask id="PlaneMask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="0" y="18" width="12" height="12">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 18.314H11.6845V30H0V18.314Z" fill="white" />
    </mask>
    <g mask="url(#PlaneMask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.401 18.314C3.12447 18.314 10.807 19.157 10.807 19.157C10.807 19.157 11.6834 26.8605 11.6834 28.5756C11.6834 30.2907 9.95991 30.2907 9.11279 29.4186C8.26566 28.5756 5.69506 24.2733 5.69506 24.2733C5.69506 24.2733 1.401 21.7442 0.553876 20.8721C-0.293252 20.0291 -0.293252 18.314 1.401 18.314Z"
        fill="#68B6FF"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.12564 4.65116C5.69623 4.62209 24.5083 5.46512 24.5083 5.46512C24.5083 5.46512 25.297 24.3023 25.3262 26.8605C25.3554 29.4186 22.9601 30.2907 22.0253 26.8605C21.0906 23.4302 17.6729 12.2965 17.6729 12.2965C17.6729 12.2965 5.72544 8.51744 3.12564 7.93605C-0.292082 7.18023 0.555046 4.68023 3.12564 4.65116Z"
      fill="#68B6FF"
    />
    <mask id="PlaneMask1" mask-type="alpha" maskUnits="userSpaceOnUse" x="4" y="0" width="26" height="26">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.58618 0H30V25.436H4.58618V0Z" fill="white" />
    </mask>
    <g mask="url(#PlaneMask1)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.7844 2.03465C24.5079 0.319533 28.7727 -0.523491 29.6198 0.319533C30.467 1.16256 29.6198 5.46488 27.8964 7.18C26.1729 8.89511 11.6841 22.587 11.6841 22.587C11.6841 22.587 6.13395 26.4533 4.84865 25.1742C3.56335 23.866 7.39003 18.3137 7.39003 18.3137C7.39003 18.3137 21.0901 3.74977 22.7844 2.03465Z"
        fill="#F1F1F1"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.6845 18.314C11.6845 18.314 12.1227 18.75 8.26682 22.5872C4.41093 26.4535 3.97277 26.0174 3.97277 26.0174C3.97277 26.0174 3.56381 25.5814 7.39049 21.7442C11.2464 17.8779 11.6845 18.314 11.6845 18.314Z"
      fill="#0083FF"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24.5083 2.90697C25.9397 2.90697 27.0789 4.06976 27.0789 5.46511H27.7508C27.8384 5.20349 27.926 4.91279 27.926 4.62209C27.926 3.19767 26.7868 2.06395 25.3554 2.06395C25.0633 2.06395 24.7712 2.12209 24.5083 2.23837V2.90697Z"
      fill="#0083FF"
    />
  </svg>
);

export default Plane;
