export enum ColorVariants {
  Lightest = 'Lightest',
  Lighter = 'Lighter',
  Light = 'Light',
  Regular = 'Regular',
  Dark = 'Dark',
  Darker = 'Darker',
  Darkest = 'Darkest',
  Transparent = 'Transparent'
}

export type VariantMap = {
  [key in ColorVariants]?: string;
};

export type ColorMap = {
  [key: string]: VariantMap;
};

export const Colors: ColorMap = {
  Blue: {
    Lightest: '#F7FBFE',
    Lighter: '#F0F7FC',
    Light: '',
    Regular: '',
    Dark: '',
    Darker: '',
    Darkest: ''
  },
  Primary: {
    Light: '#E8F6F1',
    Regular: '#16A974',
    Transparent: 'rgba(22, 169, 116, 0.25)'
  },
  Secondary: {
    Regular: '#2D3C56'
  },
  White: {
    Regular: '#FFFFFF'
  },
  Black: {
    Regular: '#080808'
  },
  Grey: {
    Lightest: '',
    Lighter: '#E1E2EB',
    Light: '#D8D9DB',
    Regular: '#979797',
    Dark: '',
    Darker: '',
    Darkest: ''
  }
};

const _8PX_GRID = {
  _05x: 4,
  _1x: 8,
  _2x: 16,
  _3x: 24,
  _4x: 32,
  _5x: 40,
  _6x: 48,
  _8x: 64,
  _10x: 80,
  _12x: 96
};

export const BorderRadius = {
  Button: 4,
  Card: 8,
  Checkbox: 2
};

export const Spacing = {
  xs: _8PX_GRID._05x,
  s: _8PX_GRID._1x,
  m: _8PX_GRID._2x,
  l: _8PX_GRID._3x,
  xl: _8PX_GRID._4x,
  xxl: _8PX_GRID._5x
};

export const Font = {
  sizes: {
    xs: '12px',
    s: '14px',
    m: '16px',
    l: '20px',
    xl: '24px',
    xxl: '36px',
    xxxl: '48px'
  },
  weight: {
    lighter: 300,
    light: 400,
    regular: 500,
    bold: 600,
    bolder: 700
  }
};

export const Breakpoints = {
  mobileMaxWidth: '767px',
  tabletMinWidth: '768px',
  laptopMinWidth: '1024px'
};

export const Sizing = {
  Layout: {
    Container: {
      Framed: {
        BorderWidth: 1,
        Padding: _8PX_GRID._4x
      }
    },
    Icon: {
      MaxSize: _8PX_GRID._12x,
      RegularSize: _8PX_GRID._10x,
      MobileSize: _8PX_GRID._6x
    },
    Input: {
      MinWidth: 160,
      MaxWidth: 320,
      SideMargin: _8PX_GRID._1x
    },
    Tooltip: {
      MaxWidth: 200
    }
  },
  Menu: {
    MobileHeight: _8PX_GRID._10x,
    Modules: {
      MaxHeight: 496
    },
    Width: 360,
    Step: {
      Width: _8PX_GRID._6x,
      BorderWidth: _8PX_GRID._1x
    }
  },
  Dashboard: {
    Padding: _8PX_GRID._2x,
    Widget: {
      TitleSVGSize: _8PX_GRID._8x,
      TitleSVGMargin: _8PX_GRID._3x
    }
  }
};

export const MIN_WIDTH_HORIZONTAL_INPUTS =
  Sizing.Menu.Width +
  2 * Sizing.Dashboard.Padding +
  2 * (Sizing.Layout.Container.Framed.BorderWidth + Sizing.Layout.Container.Framed.Padding) +
  3 * Sizing.Layout.Input.MinWidth +
  2 * Sizing.Layout.Input.SideMargin;
export const MIN_WIDTH_HORIZONTAL_INPUTS_WITH_SVG =
  MIN_WIDTH_HORIZONTAL_INPUTS + Sizing.Dashboard.Widget.TitleSVGSize + Sizing.Dashboard.Widget.TitleSVGMargin;
