import React from 'react';

type DotsProps = {
  size?: number;
};

const Dots: React.FC<DotsProps> = ({ size = 32 }) => (
  <svg width={size} height={size} viewBox="0 0 30 9" fill="none">
    <path
      d="M3.94397 8.33333C1.77802 8.33333 0 6.45492 0 4.16667C0 1.87841 1.77802 0 3.94397 0C6.10991 0 7.88793 1.87841 7.88793 4.16667C7.88793 6.45492 6.10991 8.33333 3.94397 8.33333Z"
      fill="#0083FF"
    />
    <path
      d="M5.78664 6.9672C3.62069 6.9672 1.84267 5.08879 1.84267 2.80053C1.84267 1.84425 2.16595 0.956272 2.68319 0.239059C1.13147 0.785508 0 2.35655 0 4.16665C0 6.45491 1.77802 8.33332 3.94396 8.33332C5.20474 8.33332 6.33621 7.68441 7.04741 6.72813C6.65948 6.86474 6.23922 6.9672 5.78664 6.9672Z"
      fill="#68B6FF"
    />
    <path
      d="M15 8.33333C12.834 8.33333 11.056 6.45492 11.056 4.16667C11.056 1.87841 12.834 0 15 0C17.1659 0 18.9439 1.87841 18.9439 4.16667C18.9439 6.45492 17.1659 8.33333 15 8.33333Z"
      fill="#0083FF"
    />
    <path
      d="M16.8427 6.9672C14.6767 6.9672 12.8987 5.08879 12.8987 2.80053C12.8987 1.84425 13.222 0.956272 13.7392 0.239059C12.1875 0.785508 11.056 2.35655 11.056 4.16665C11.056 6.45491 12.834 8.33332 15 8.33332C16.2608 8.33332 17.3922 7.68441 18.1034 6.72813C17.7155 6.86474 17.2952 6.9672 16.8427 6.9672Z"
      fill="#68B6FF"
    />
    <path
      d="M26.056 8.33333C23.8901 8.33333 22.1121 6.45492 22.1121 4.16667C22.1121 1.87841 23.8577 0 26.056 0C28.222 0 30 1.87841 30 4.16667C30 6.45492 28.222 8.33333 26.056 8.33333Z"
      fill="#0083FF"
    />
    <path
      d="M27.8987 6.9672C25.7328 6.9672 23.9547 5.08879 23.9547 2.80053C23.9547 1.84425 24.278 0.956272 24.7952 0.239059C23.2435 0.785508 22.1121 2.35655 22.1121 4.16665C22.1121 6.45491 23.8901 8.33332 26.056 8.33332C27.3168 8.33332 28.4483 7.68441 29.1595 6.72813C28.7715 6.86474 28.3513 6.9672 27.8987 6.9672Z"
      fill="#68B6FF"
    />
  </svg>
);

export default Dots;
