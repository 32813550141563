import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { Link, useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { useForm, Controller } from 'react-hook-form';

import { Breakpoints, Colors, Font, Spacing } from '../../../styles/constants';

import { trackSubmitEmail } from '../../../data/tracking/google_analytics';

import { Arrow, Logo } from '../../../components/Icon';
import { Body } from '../../../components/Layout/Text';
import { Container, Content, Header } from '../../../components/Layout/Page/V2';
import { Horizontal, Vertical } from '../../../components/Layout/Containers';
import { QuestionTitle } from '../../../components/Layout/Title';
import { Form, Input } from '../../../components/Form';
import Button from '../../../components/Layout/Button';

const StyledNavigationHeader = styled(Horizontal)`
  position: absolute;
  top: 0;
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.Blue.Lighter};

  & > a {
    position: absolute;
    left: ${Spacing.m}px;
  }

  & > ${Body} {
    justify-self: center;
  }
`;

const AnimationContainer = styled(Vertical)`
  align-items: center;
  display: none;
`;

const StyledButton = styled(Button)`
  height: 56px;
  font-size: ${Font.sizes.m};
  padding: ${Spacing.m}px ${Spacing.xxl}px;

  &:focus {
    outline-color: transparent;
  }

  & > svg {
    margin-left: ${Spacing.s}px;
  }
`;

const EmailViewHeader: React.FC<{}> = () => {
  return (
    <StyledNavigationHeader>
      <Link to="/project/3">
        <Arrow size={24} rotation={180} color="Secondary.Regular" />
      </Link>
      <Body>
        <b>Votre projet</b> 4/4
      </Body>
    </StyledNavigationHeader>
  );
};

type EmailFormData = {
  email: string;
};

const EmailView: React.FC<{}> = () => {
  const isLaptop = useMediaQuery({ query: `(min-width: ${Breakpoints.laptopMinWidth})` });
  const [animate, setAnimate] = useState(false);
  const history = useHistory();
  const { handleSubmit, control } = useForm<EmailFormData>({
    defaultValues: { email: '' }
  });
  const [initialHeight] = useState(window.innerHeight);

  const toggleTransition = () => setAnimate(!animate);

  const onSubmit = ({ email }: EmailFormData) => {
    trackSubmitEmail(email);
    toggleTransition();
    setTimeout(() => history.push('/processing'), 200);
  };

  const onResize = () => {
    document.documentElement.style.setProperty('overflow', 'auto');
    document
      .querySelector('meta[name=viewport]')
      ?.setAttribute('content', `height=${initialHeight}, width=device-width, initial-scale=1.0`);
  };

  useEffect(() => {
    toggleTransition();

    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <Container>
      <Header>{isLaptop ? <Logo /> : <EmailViewHeader />}</Header>
      <Content padded={isLaptop}>
        {isLaptop && <EmailViewHeader />}
        <CSSTransition classNames="slide" timeout={200} appear in={animate}>
          <AnimationContainer>
            <QuestionTitle>Quelle est votre adresse email ?</QuestionTitle>
            <Body>Votre mail vous permettra de vous reconnecter sur mylegalstart</Body>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="email"
                control={control}
                render={({ onChange }) => (
                  <Input
                    name="email"
                    type="email"
                    placeholder="exemple@gmail.com"
                    onChange={(e) => onChange(e.target.value)}
                  />
                )}
              />
              <StyledButton type="submit">
                Préparer mon projet <Arrow color="White.Regular" size={20} />
              </StyledButton>
            </Form>
          </AnimationContainer>
        </CSSTransition>
      </Content>
    </Container>
  );
};

export default EmailView;
