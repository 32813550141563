import styled, { css } from 'styled-components';

import { Breakpoints, Colors, Font, Spacing } from '../../../styles/constants';

const CommonTitle = css`
  line-height: 1.5;
  font-weight: ${Font.weight.bolder};
  text-align: center;
  color: ${Colors.Secondary.Regular};
`;

export const MainTitle = styled.h1`
  ${CommonTitle};
  font-size: ${Font.sizes.xxl};
  margin: 0 0 ${Spacing.l}px 0;
`;

export const QuestionTitle = styled.h2`
  ${CommonTitle};
  font-size: 20px;
  margin: 0 0 ${Spacing.m}px 0;

  @media (min-width: ${Breakpoints.laptopMinWidth}) {
    font-size: 32px;
  }
`;
