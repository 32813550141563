import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { Link, useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { Breakpoints, Colors, Spacing } from '../../../styles/constants';

import { trackSituationClick } from '../../../data/tracking/google_analytics';

import { Arrow, Dots, Employed, LawHammer, Logo, Rocket, Student, Unemployed } from '../../../components/Icon';
import { QuestionTitle } from '../../../components/Layout/Title';
import { Body, Intro } from '../../../components/Layout/Text';
import { Container, Content, Header } from '../../../components/Layout/Page/V2';
import ItemList from '../../../components/Layout/ItemList';
import { Horizontal, Vertical } from '../../../components/Layout/Containers';

const StyledNavigationHeader = styled(Horizontal)`
  position: absolute;
  top: 0;
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.Blue.Lighter};

  & > a {
    position: absolute;
    left: ${Spacing.m}px;
  }

  & > ${Body} {
    justify-self: center;
  }

  @media (min-width: ${Breakpoints.laptopMinWidth}) {
  }
`;

const AnimationContainer = styled(Vertical)`
  align-items: center;
  display: none;
`;

const SITUATION_OPTIONS = [
  {
    label: 'Salarié',
    value: 'employed',
    icon: <Employed />
  },
  {
    label: 'Entrepreneur',
    value: 'entrepreneur',
    icon: <Rocket />
  },
  {
    label: 'Demandeur d’emploi',
    value: 'unemployed',
    icon: <Unemployed />
  },
  {
    label: 'Étudiant',
    value: 'student',
    icon: <Student />
  },
  {
    label: 'Professionnel du droit',
    value: 'law_professional',
    icon: <LawHammer />
  },
  {
    label: 'Autre',
    value: 'other',
    icon: <Dots />
  }
];

const SituationViewHeader: React.FC<{}> = () => {
  return (
    <StyledNavigationHeader>
      <Link to="/welcome">
        <Arrow size={24} rotation={180} color="Secondary.Regular" />
      </Link>
      <Body>
        <b>Votre projet</b> 1/4
      </Body>
    </StyledNavigationHeader>
  );
};

const SituationView: React.FC<{}> = () => {
  const isLaptop = useMediaQuery({ query: `(min-width: ${Breakpoints.laptopMinWidth})` });
  const [animate, setAnimate] = useState(false);
  const history = useHistory();

  const toggleTransition = () => setAnimate(!animate);

  const handleItemSelect = (itemValue: string) => {
    toggleTransition();
    trackSituationClick(itemValue);
    setTimeout(() => history.push('/project/2'), 100);
  };

  useEffect(() => {
    toggleTransition();
  }, []);

  return (
    <Container>
      <Header>{isLaptop ? <Logo /> : <SituationViewHeader />}</Header>
      <Content padded={isLaptop}>
        {isLaptop && <SituationViewHeader />}
        <CSSTransition classNames="slide" timeout={200} appear in={animate}>
          <AnimationContainer>
            <Intro>Dites-nous en plus sur vous</Intro>
            <QuestionTitle>Vous êtes actuellement :</QuestionTitle>
            <ItemList options={SITUATION_OPTIONS} onItemSelected={handleItemSelect} onItemSelectedTimeout={500} />
          </AnimationContainer>
        </CSSTransition>
      </Content>
    </Container>
  );
};

export default SituationView;
