import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { Font, Spacing } from '../../../styles/constants';

import { ButtonStyle } from '../Button';

// TODO : maybe plan / handle a left / right icon placement (here, it's fixed to the right)
const StyledLink = styled(Link)`
  ${ButtonStyle};
  font-size: ${Font.sizes.l};
  margin-top: ${Spacing.xl}px;
  padding: ${Spacing.m}px ${Spacing.xxl}px;

  & > svg {
    margin-left: ${Spacing.s}px;
  }
`;

export default StyledLink;
