import styled from 'styled-components';

import { Breakpoints, Colors, Spacing, Sizing, BorderRadius } from '../../../../styles/constants';

const FramedBlock = styled.div`
  padding: ${Sizing.Layout.Container.Framed.Padding}px;
  border: ${Sizing.Layout.Container.Framed.BorderWidth}px solid ${Colors.Grey.Lighter};
  box-shadow: 0px 0px 8px rgba(225, 226, 235, 0.6);
  border-radius: ${BorderRadius.Card}px;
  background-color: ${Colors.White.Regular};

  @media (max-width: ${Breakpoints.mobileMaxWidth}) {
    padding: ${Spacing.l}px;
  }

  & + * {
    margin-top: ${Spacing.m}px;
  }

  & > *:first-child {
    margin-top: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }
`;

export default FramedBlock;
