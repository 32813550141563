import styled from 'styled-components';

import { Spacing } from '../../styles/constants';

export const Form = styled.form`
  margin-top: ${Spacing.xl}px;
  max-width: 300px;
  width: 100%;

  & > * {
    max-width: 100%;
    width: 100%;

    & + * {
      margin-top: ${Spacing.m}px;
    }
  }
`;

export { default as Input } from './Input';
