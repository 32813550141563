import React from 'react';

import { getColor } from '../../../helpers';

import { Colors } from '../../../styles/constants';

type StarProps = {
  size?: number;
  color?: string;
};

const Star: React.FC<StarProps> = ({ size = 32, color = 'White.Regular' }) => (
  <svg width={size} height={size} viewBox="0 0 12 11" fill="none">
    <path
      d="M6 0L7.34708 4.1459H11.7063L8.17963 6.7082L9.52671 10.8541L6 8.2918L2.47329 10.8541L3.82037 6.7082L0.293661 4.1459H4.65292L6 0Z"
      fill={getColor(Colors, color) || Colors.White.Regular}
    />
  </svg>
);

export default Star;
