import styled from 'styled-components';

import { Spacing, Colors, BorderRadius, Font } from '../../../styles/constants';

const Input = styled.input`
  padding: ${Spacing.m}px;
  background-color: ${Colors.White.Regular};
  border: 1px solid ${Colors.Grey.Light};
  border-radius: ${BorderRadius.Button}px;
  max-width: 300px;
  width: 100%;
  height: 56px;
  font-size: ${Font.sizes.m};
  color: ${Colors.Secondary.Regular};

  &:focus {
    outline: none;
    border-color: ${Colors.Primary.Regular};
  }
`;

export default Input;
