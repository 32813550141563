import React from 'react';

type StudentProps = {
  size?: number;
};

const Student: React.FC<StudentProps> = ({ size = 32 }) => (
  <svg width={size} height={size} viewBox="0 0 30 24" fill="none">
    <path
      d="M9.79427 0H26.2433L29.9732 16.3508H28.6047L28.5778 22.1294H30V23.0881H7.75492C5.66189 22.9283 5.84973 19.2002 5.71556 18.2149L7.59392 1.59779C7.59392 0.878787 8.37209 0 9.79427 0Z"
      fill="#D1E9FF"
    />
    <path
      d="M9.79428 0H11.3775L9.95529 23.1148H7.75493C5.1789 22.8218 5.07156 18.7475 5.90341 16.5638L7.59393 1.62442C7.59393 0.878787 8.3721 0 9.79428 0Z"
      fill="#68B6FF"
    />
    <path
      d="M8.05008 16.3508H29.5707C28.6046 18.3214 28.6315 20.292 29.5707 22.2093H8.05008C5.74238 22.2093 5.74238 16.3508 8.05008 16.3508Z"
      fill="#F1F1F1"
    />
    <path
      d="M6.46691 17.9752H28.9803C28.9803 18.0285 28.9535 18.0817 28.9535 18.1616H6.44008C6.44008 18.0817 6.46691 18.0285 6.46691 17.9752ZM28.8998 18.5877C28.8998 18.6409 28.8998 18.6942 28.873 18.7741H6.33274C6.33274 18.7208 6.33274 18.6676 6.35958 18.5877H28.8998ZM28.8462 19.2002C28.8462 19.2534 28.8462 19.3067 28.8462 19.3866H6.30591C6.30591 19.3333 6.30591 19.2801 6.30591 19.2002H28.8462ZM28.873 19.8127C28.873 19.8659 28.873 19.9192 28.8998 19.9991H6.35958C6.35958 19.9458 6.33274 19.8926 6.33274 19.8127H28.873ZM28.9535 20.4251C28.9535 20.4784 28.9803 20.5317 28.9803 20.6116H6.46691C6.46691 20.5583 6.44008 20.505 6.44008 20.4251H28.9535ZM29.1145 21.0376C29.1413 21.0909 29.1413 21.1442 29.1682 21.224H6.70841C6.68158 21.1708 6.65475 21.1175 6.62791 21.0376H29.1145ZM29.3292 21.6768C29.356 21.73 29.3828 21.7833 29.4097 21.8632H7.13775C7.08408 21.8099 7.03042 21.7566 6.97675 21.6768H29.3292Z"
      fill="#D1E9FF"
    />
    <path
      d="M10.3041 17.5757H27.6386H28.6315H29.0877C29.195 17.1763 29.356 16.7502 29.5707 16.3508H28.0411H10.3578H8.05008C5.90339 16.3508 5.74239 21.3838 7.56708 22.1294C6.94991 20.3985 7.43291 17.6024 9.04293 17.6024L10.3041 17.5757Z"
      fill="#D1E9FF"
    />
    <path
      d="M18.9982 6.25803L19.1861 9.48025C18.1396 11.2378 14.7853 12.5427 11.4848 12.5161C8.31844 12.4628 5.20574 11.1846 4.37389 9.53351L4.34706 6.39118L11.3775 8.30854L18.9982 6.25803Z"
      fill="#0666C1"
    />
    <path d="M0 5.13956L11.3775 1.30486L22.7549 5.11293L11.3775 8.94764L0 5.13956Z" fill="#0083FF" />
    <path
      d="M4.05189 12.9421L3.51521 16.2442C3.51521 16.2975 3.70305 16.3774 3.78355 16.2709C3.89088 16.404 4.05189 16.3508 4.10555 16.2442C4.15922 16.3508 4.29339 16.3774 4.40072 16.2442C4.48122 16.3508 4.69589 16.2709 4.66906 16.191L4.15922 12.9155C4.13239 12.8889 4.05189 12.8889 4.05189 12.9421Z"
      fill="#0083FF"
    />
    <path
      d="M11.6458 4.76674C11.7263 4.74011 11.78 4.79337 11.8068 4.84663C11.8336 4.92652 11.78 4.97978 11.7263 5.00641L4.40072 7.19006V13.528C4.40072 13.6079 4.34705 13.6611 4.26655 13.6611C4.18605 13.6611 4.13239 13.6079 4.13239 13.528V7.11017C4.13239 7.05691 4.15922 7.00365 4.21289 6.97702L11.6458 4.76674Z"
      fill="#D1E9FF"
    />
    <path
      d="M4.29339 13.9807C4.58856 13.9807 4.80323 13.741 4.80323 13.4747C4.80323 13.2084 4.56173 12.9421 4.29339 12.9421C3.99822 12.9421 3.78355 13.1818 3.78355 13.4481C3.75672 13.741 3.99822 13.9807 4.29339 13.9807Z"
      fill="#D1E9FF"
    />
    <path
      d="M2.97853 5.77867C2.97853 5.99171 2.84436 6.17812 2.65652 6.23138C2.60286 6.25801 2.54919 6.25801 2.49552 6.25801C2.33452 6.25801 2.17352 6.17812 2.09302 6.04497C2.14668 6.23138 2.28085 6.36453 2.46869 6.39116C2.49552 6.39116 2.54919 6.39116 2.57602 6.39116C2.60286 6.39116 2.65652 6.39116 2.68336 6.39116C2.92486 6.3379 3.08586 6.12486 3.08586 5.88519C3.08586 5.75204 3.03219 5.61889 2.92486 5.51237C2.95169 5.61889 2.97853 5.69878 2.97853 5.77867Z"
      fill="#D1E9FF"
    />
  </svg>
);

export default Student;
