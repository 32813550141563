import React from 'react';

export enum CardContentEnum {
  Article = 'ARTICLE',
  Video = 'VIDEO'
}

export type ContentItemType = {
  title: string;
  duration: number;
  imageUrl: string;
  redirectUrl: string;
  id: string;
  type: CardContentEnum;
  description?: JSX.Element;
};

export type ContentSectionType = {
  listTitle: string;
  contentCardList: Array<ContentItemType>;
};

// last used hardcoded ID : 32
export const contentData = [
  {
    listTitle: 'Contenu Populaire',
    contentCardList: [
      {
        title: 'Comment faire un business plan ?',
        duration: 3,
        imageUrl:
          'https://legalstart.s3-eu-west-1.amazonaws.com/Images+fiches+pratiques+LS/Business+Plan/business+plan+financier.jpg',
        redirectUrl: 'https://www.legalstart.fr/fiches-pratiques/astuces-entrepreneurs/comment-faire-business-plan/',
        id: '1',
        type: CardContentEnum.Article
      },
      {
        title: 'Valentin Cauzit, fondateur de VDC Prestige',
        duration: 4,
        imageUrl: 'https://drive.google.com/uc?id=1BQJt3REuENrTquxT3Fz8hqbCGOzCA5xP',
        redirectUrl: 'https://www.youtube.com/embed/6am-R5iS40o?enablejsapi=1',
        id: '33',
        type: CardContentEnum.Video,
        description: (
          <>
            Valentin Cauzit, fondateur de VDC Prestige 🚕
            <br />
            Dans cet épisode, Valentin nous raconte son parcours passionnant, de ses débuts en tant que chasseur alpin
            au sein de l’armée de Terre jusqu’à la création de son entreprise de transport, mais aussi sa joie et sa
            satisfaction d’être son propre propre patron 😍
            <br />
            Découvrez son interview en intégralité 🎥
          </>
        )
      },
      {
        title: 'Comment financer mon activité ?',
        duration: 4,
        imageUrl: 'https://yolaw-production.s3.amazonaws.com/publicmedia/Financement_participatif_association.jpg',
        redirectUrl: 'https://www.legalstart.fr/fiches-pratiques/financement/financement-de-projet/',
        id: '2',
        type: CardContentEnum.Article
      },
      {
        title: "Quelles sont les aides auxquelles j'ai droit ?",
        duration: 5,
        imageUrl:
          'https://s3-eu-west-1.amazonaws.com/legalstart/Images+fiches+pratiques+LS/Cr%C3%A9er+sa+soci%C3%A9t%C3%A9/Salarie%CC%81+cre%CC%81ateur.jpg',
        redirectUrl: 'https://www.legalstart.fr/fiches-pratiques/aides-creation-entreprise/',
        id: '3',
        type: CardContentEnum.Article
      },
      {
        title: 'Quelles sont les étapes pour créer sa société ?',
        duration: 3,
        imageUrl:
          'https://legalstart.s3-eu-west-1.amazonaws.com/Images+fiches+pratiques+LS/Accompagnement/Cre%CC%81er+sa+propre+entreprise.jpg',
        redirectUrl: 'https://www.legalstart.fr/fiches-pratiques/creer-sa-societe/etapes-de-creation-entreprise/',
        id: '4',
        type: CardContentEnum.Article
      },
      {
        title: 'Comment choisir le bon statut juridique ?',
        duration: 2,
        imageUrl: 'https://yolaw-production.s3.amazonaws.com/publicmedia/cytonn-photography-604680-unsplash.jpg',
        redirectUrl: 'https://www.legalstart.fr/choix-forme-juridique/',
        id: '5',
        type: CardContentEnum.Article
      }
    ]
  },
  {
    listTitle: 'Créer sa société',
    contentCardList: [
      {
        title: 'Quelles sont les étapes pour créer sa société ?',
        duration: 3,
        imageUrl:
          'https://legalstart.s3-eu-west-1.amazonaws.com/Images+fiches+pratiques+LS/Accompagnement/Cre%CC%81er+sa+propre+entreprise.jpg',
        redirectUrl: 'https://www.legalstart.fr/fiches-pratiques/creer-sa-societe/etapes-de-creation-entreprise/',
        id: '6',
        type: CardContentEnum.Article
      },
      {
        title: 'Tout savoir sur la dénomination sociale',
        duration: 2,
        imageUrl:
          'https://s3-eu-west-1.amazonaws.com/legalstart/Images+fiches+pratiques+LS/D%C3%A9nomination/D%C3%A9finition+d%C3%A9nomination+sociale.jpg',
        redirectUrl: 'https://www.legalstart.fr/fiches-pratiques/raison-sociale/denomination-sociale/',
        id: '7',
        type: CardContentEnum.Article
      },
      {
        title: "Capital social d'une entreprise",
        duration: 3,
        imageUrl:
          'https://s3-eu-west-1.amazonaws.com/legalstart/Images+fiches+pratiques+LS/Recruter+un+salari%C3%A9/Rompre_Un_CDD.jpg',
        redirectUrl: 'https://www.legalstart.fr/fiches-pratiques/capital-social/',
        id: '8',
        type: CardContentEnum.Article
      },
      {
        title: 'En quoi consiste la domiciliation d’une entreprise?',
        duration: 3,
        imageUrl:
          "https://s3-eu-west-1.amazonaws.com/legalstart/Images+fiches+pratiques+LS/Astuces+entrepreneurs/Domiciliation+d'entreprise.jpg",
        redirectUrl: 'https://www.legalstart.fr/fiches-pratiques/astuces-entrepreneurs/domiciliation-entreprise/',
        id: '9',
        type: CardContentEnum.Article
      }
    ]
  },
  {
    listTitle: "Interviews d'entrepreneurs",
    contentCardList: [
      {
        title: 'Olivier Ramel, CEO et co-fondateur de Kymono',
        duration: 5,
        imageUrl: 'https://drive.google.com/uc?id=1Bf84JgMZuWTa-kvwUrJpx1dohctJlGPQ',
        redirectUrl: 'https://www.youtube.com/embed/GX4FMIgPx0o?enablejsapi=1',
        id: '26',
        type: CardContentEnum.Video,
        description: (
          <>
            Olivier Ramel CEO et co-fondateur de kymono.co : "L'important c'est toujours de s'éclater" 👊💪🔥
            <br />
            <br />
            Il nous raconte son parcours entrepreneurial, son rêve d'enfant de jouer en NBA et sa rencontre avec son
            associée 💡
            <br />
            <br />
            Découvrez son interview en intégralité !
          </>
        )
      },
      {
        title: 'Sarah ZEDIRA, fondatrice de Travel mate',
        duration: 5,
        imageUrl: 'https://drive.google.com/uc?id=1BasUjX_OYovpLYcQD-YTAnmro6HKUIeW',
        redirectUrl: 'https://www.youtube.com/embed/VCuga632kP8?enablejsapi=1',
        id: '27',
        type: CardContentEnum.Video,
        description: (
          <>
            Sarah ZEDIRA, étudiante et auto-entrepreneur, fondatrice de @heytravelmate 🌍
            <br />
            <br />
            Dans cet épisode, elle nous raconte comment elle a réussi à concilier ses études et la création de son
            auto-entreprise 💪
            <br />
            <br />
            Découvrez son interview en intégralité ! 🎬
          </>
        )
      },
      {
        title: 'Fabrice Gepner, fondateur LE CHEESE GEEK',
        duration: 5,
        imageUrl: 'https://drive.google.com/uc?id=1ByspfqqqBPenwpzv0FN-ncVoNrZ8tdl0',
        redirectUrl: 'https://www.youtube.com/embed/6waw85s8LhE?enablejsapi=1',
        id: '28',
        type: CardContentEnum.Video,
        description: (
          <>
            Fabrice Gepner, fondateur LE CHEESE GEEK et Questions Pour Une Fine Bouche 🧀
            <br />
            <br />
            Dans cet épisode, Fabrice nous raconte son parcours avec humour. Notamment sa reconversion en artisan
            fromager, le lancement de son atelier de dégustation, mais aussi comment il a su s'adapter son business à la
            crise actuelle 💪👊
            <br />
            <br />
            Découvrez son interview en intégralité ! 📽
          </>
        )
      },
      {
        title: 'Jeremy Langlais, freelance digital',
        duration: 4,
        imageUrl: 'https://drive.google.com/uc?id=1BehdPmiT2lKohsGEEci_VE9-1eYKSTVY',
        redirectUrl: 'https://www.youtube.com/embed/pWKd85P_Z0I?enablejsapi=1',
        id: '29',
        type: CardContentEnum.Video,
        description: (
          <>
            Jeremy Langlais, freelance digital 💻
            <br />
            Après un parcours dans l'informatique en tant que développeur puis CTO, Jeremy, polyvalent par excellence, a
            décidé de se lancer à son compte 🚀
            <br />
            Aujourd’hui il est heureux de retrouver son indépendance et surtout d’apporter ses précieux conseils aux
            entrepreneurs en difficulté 💡
            <br />
            Découvrez son parcours en intégralité 🎬
          </>
        )
      },
      {
        title: 'Valentin Cauzit, fondateur de VDC Prestige',
        duration: 4,
        imageUrl: 'https://drive.google.com/uc?id=1BQJt3REuENrTquxT3Fz8hqbCGOzCA5xP',
        redirectUrl: 'https://www.youtube.com/embed/6am-R5iS40o?enablejsapi=1',
        id: '30',
        type: CardContentEnum.Video,
        description: (
          <>
            Valentin Cauzit, fondateur de VDC Prestige 🚕
            <br />
            Dans cet épisode, Valentin nous raconte son parcours passionnant, de ses débuts en tant que chasseur alpin
            au sein de l’armée de Terre jusqu’à la création de son entreprise de transport, mais aussi sa joie et sa
            satisfaction d’être son propre propre patron 😍
            <br />
            Découvrez son interview en intégralité 🎥
          </>
        )
      }
    ]
  },
  {
    listTitle: 'Aides à la création',
    contentCardList: [
      {
        title: "Quelles sont les aides auxquelles j'ai droit ?",
        duration: 5,
        imageUrl: 'https://yolaw-production.s3.amazonaws.com/publicmedia/403549business690675640.jpg',
        redirectUrl: 'https://www.legalstart.fr/fiches-pratiques/aides-creation-entreprise/',
        id: '11',
        type: CardContentEnum.Article
      },
      {
        title: 'La prime d’activité',
        duration: 3,
        imageUrl:
          'https://legalstart.s3-eu-west-1.amazonaws.com/Images+fiches+pratiques+LS/Aides+%C3%A0+la+cr%C3%A9ation/plafond+prime+activite%CC%81.jpg',
        redirectUrl: 'https://www.legalstart.fr/fiches-pratiques/aides-a-la-creation/plafond-prime-activite/',
        id: '12',
        type: CardContentEnum.Article
      },
      {
        title: "L'ACCRE",
        duration: 4,
        imageUrl: 'https://yolaw-production.s3.amazonaws.com/publicmedia/remplirformulaireaccre.jpg',
        redirectUrl: 'https://www.legalstart.fr/fiches-pratiques/aides-creation-entreprise/demande-accre/',
        id: '13',
        type: CardContentEnum.Article
      },
      {
        title: 'Les aides Pôle Emploi',
        duration: 7,
        imageUrl:
          'https://s3-eu-west-1.amazonaws.com/legalstart/Images+fiches+pratiques+LS/Aides+%C3%A0+la+cr%C3%A9ation/aides+pole+emploi.jpg',
        redirectUrl:
          'https://www.legalstart.fr/fiches-pratiques/aides-creation-entreprise/aide-creation-entreprise-pole-emploi/',
        id: '14',
        type: CardContentEnum.Article
      }
    ]
  },
  {
    listTitle: 'Business Model',
    contentCardList: [
      {
        title: 'Comment faire une étude de marché ?',
        duration: 4,
        imageUrl: 'https://legalstart.s3-eu-west-1.amazonaws.com/Images+fiches+pratiques+LS/Blog/etude+de+marche.jpg',
        redirectUrl: 'https://www.legalstart.fr/fiches-pratiques/business-plan/etude-de-marche/',
        id: '16',
        type: CardContentEnum.Article
      },
      {
        title: 'Comment trouver des clients ?',
        duration: 7,
        imageUrl:
          'https://legalstart.s3-eu-west-1.amazonaws.com/Images+fiches+pratiques+LS/Astuces+entrepreneurs/prospection+commerciale.jpg',
        redirectUrl: 'https://www.legalstart.fr/fiches-pratiques/astuces-entrepreneurs/prospection-commerciale/',
        id: '17',
        type: CardContentEnum.Article
      },
      {
        title: 'Comment fixer un prix de vente ?',
        duration: 5,
        imageUrl:
          'https://legalstart.s3-eu-west-1.amazonaws.com/Images+fiches+pratiques+LS/Astuces+entrepreneurs/comment+fixer+un+prix+de+vente.jpg',
        redirectUrl: 'https://www.legalstart.fr/fiches-pratiques/astuces-entrepreneurs/comment-fixer-un-prix-de-vente/',
        id: '18',
        type: CardContentEnum.Article
      },
      {
        title: 'Comment faire un Business Plan ?',
        duration: 3,
        imageUrl:
          'https://s3-eu-west-1.amazonaws.com/legalstart/Images+fiches+pratiques+LS/Astuces+entrepreneurs/Comment+faire+un+business+plan.jpg',
        redirectUrl: 'https://www.legalstart.fr/fiches-pratiques/astuces-entrepreneurs/comment-faire-business-plan/',
        id: '19',
        type: CardContentEnum.Article
      }
    ]
  },
  {
    listTitle: 'Derniers articles publiés',
    contentCardList: [
      {
        title: 'Ce qu’il faut savoir avant de démissionner pour créer son entreprise',
        duration: 3,
        imageUrl:
          'https://legalstart.s3-eu-west-1.amazonaws.com/Images+fiches+pratiques+LS/Accompagnement/De%CC%81missionner+et+cre%CC%81er+son+entreprise.jpg',
        redirectUrl: 'https://www.legalstart.fr/fiches-pratiques/accompagnement/demission-pour-creation-d-entreprise/',
        id: '21',
        type: CardContentEnum.Article
      },
      {
        title: 'Créer une entreprise à deux',
        duration: 4,
        imageUrl:
          'https://legalstart.s3-eu-west-1.amazonaws.com/Images+fiches+pratiques+LS/Accompagnement/Cre%CC%81er+une+entreprise+a%CC%80+deux.jpg',
        redirectUrl: 'https://www.legalstart.fr/fiches-pratiques/accompagnement/creer-une-entreprise-a-deux/',
        id: '22',
        type: CardContentEnum.Article
      },
      {
        title: "La solitude de l'entrepreneur",
        duration: 5,
        imageUrl:
          'https://legalstart.s3-eu-west-1.amazonaws.com/Images+fiches+pratiques+LS/Astuces+entrepreneurs/solitude+entrepreneur.jpg',
        redirectUrl: 'https://www.legalstart.fr/fiches-pratiques/astuces-entrepreneurs/solitude-entrepreneur/',
        id: '23',
        type: CardContentEnum.Article
      },
      {
        title: 'Comment bien choisir son associer ?',
        duration: 3,
        imageUrl:
          'https://legalstart.s3-eu-west-1.amazonaws.com/Images+fiches+pratiques+LS/Accompagnement/Choisir+son+associe%CC%81.jpg',
        redirectUrl: 'https://www.legalstart.fr/fiches-pratiques/accompagnement/choisir-son-associe/',
        id: '24',
        type: CardContentEnum.Article
      },
      {
        title: 'Cumul salarié et créateur d’entreprise?',
        duration: 3,
        imageUrl:
          'https://s3-eu-west-1.amazonaws.com/legalstart/Images+fiches+pratiques+LS/Cr%C3%A9er+sa+soci%C3%A9t%C3%A9/Salarie%CC%81+cre%CC%81ateur.jpg',
        redirectUrl:
          'https://www.legalstart.fr/fiches-pratiques/creer-sa-societe/peut-on-etre-salarie-et-createur-dentreprise/',
        id: '25',
        type: CardContentEnum.Article
      }
    ]
  }
];
