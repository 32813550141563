import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';

import Layout from './components/Layout';

import { ContentSummaryView, ContentView, ProcessingView, ProjectView, WelcomeView } from './views';

const App = () => {
  return (
    <BrowserRouter basename="/">
      <Layout>
        <Switch>
          {/* NEW UI */}
          <Route exact path="/welcome">
            <WelcomeView />
          </Route>
          <Route exact path="/project/1">
            <ProjectView.Situation />
          </Route>
          <Route exact path="/project/2">
            <ProjectView.Activity />
          </Route>
          <Route exact path="/project/3">
            <ProjectView.Delay />
          </Route>
          <Route exact path="/project/4">
            <ProjectView.Email />
          </Route>
          <Route exact path="/processing">
            <ProcessingView />
          </Route>
          <Route exact path="/content/:contentId">
            <ContentView />
          </Route>
          <Route exact path="/content">
            <ContentSummaryView />
          </Route>
          <Redirect from="/project" to="/project/1" />
          <Redirect to="/" from="*" />
        </Switch>
      </Layout>
    </BrowserRouter>
  );
};

export default App;
