import React, { useEffect, useState } from 'react';
import styled, { css, keyframes } from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { Link, useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { Controller, useForm } from 'react-hook-form';

import { BorderRadius, Breakpoints, Colors, Font, Spacing } from '../../../styles/constants';

import activities from './activities.json';

import { trackActivitySelection } from '../../../data/tracking/google_analytics';

import { AngleBracket, Arrow, Cross, Logo, Magnifier } from '../../../components/Icon';
import { Body } from '../../../components/Layout/Text';
import { Container, Content, Header } from '../../../components/Layout/Page/V2';
import { Horizontal, Vertical } from '../../../components/Layout/Containers';
import { QuestionTitle } from '../../../components/Layout/Title';
import { Form, Input } from '../../../components/Form';
import Button from '../../../components/Layout/Button';

const Bounce = keyframes`
  0% {
    font-size: 1rem;
  }
  25% {
    font-size: 0.97rem;
  }
  35% {
    font-size: 0.9rem;
  }
  45% {
    font-size: 1.1rem;
  }
  55% {
    font-size: 0.9rem;
  }
  65% {
    font-size: 1.1rem;
  }
  75% {
    font-size: 1.03rem;
  }
  100% {
    font-size: 1rem;
  }
`;

type ActivityType = {
  activity: string;
  keywords: Array<string>;
};

const keywordsToActivities = activities.reduce((res: any, { activity, keywords }: ActivityType) => {
  keywords.forEach((keyword: string) => {
    res[keyword] = (res[keyword] || []).concat(activity);
  });

  return res;
}, {});

// Get rid of accents and lowercase the string to ease comparison and matching
const normalizeLabel = (label: string) =>
  label
    .trim()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '')
    .toLocaleLowerCase();

const capitalizeString = (string: string) => string.charAt(0).toUpperCase() + string.slice(1);

// search for full query match in activity labels
const findQueryInActivityLabel = (query: string) => {
  const activityLabelMatches = activities.filter(
    ({ activity }: ActivityType) => normalizeLabel(activity).indexOf(normalizeLabel(query)) > -1
  );

  if (activityLabelMatches.length > 0) return activityLabelMatches;

  return [];
};

// search for full query match in keywords and resolve associated activities
const findQueryInActivityKeywords = (query: string) => {
  const keywordMatches = Object.keys(keywordsToActivities).filter(
    (keyword) => normalizeLabel(keyword).indexOf(normalizeLabel(query)) > -1
  );
  let activitiesLabelFromKeyword: Array<any> = [];
  let activitiesFromKeyword: Array<any> = [];

  keywordMatches.forEach((keyword) => activitiesLabelFromKeyword.push(...keywordsToActivities[keyword]));
  activitiesLabelFromKeyword.forEach((activityLabel) =>
    activitiesFromKeyword.push(activities.find(({ activity }: ActivityType) => activity === activityLabel))
  );

  if (activitiesFromKeyword.length > 0) return activitiesFromKeyword;

  return [];
};

// try to split que query and search for each word in activities and keyword, then sort all the matching activities by number of matches
const findActivitiesMatchingQuery = (query: string) => {
  const queryList = normalizeLabel(query).split(' ');
  const activityRelevancyScoring: { [key: string]: number } = {};

  const RELEVANCY_FACTOR_MATCH_ACTIVITY_LABEL = 1.5;
  const RELEVANCY_FACTOR_MATCH_ACTIVITY_KEYWORD = 1;
  const MAX_SUGGESTION_ITEMS_TO_DISPLAY = 4;

  let queryMatches: Array<any> = [];

  // match each query with activity label or keyword
  queryList.forEach((queryItem) => {
    // filter out items too small to be relevant (de, la, C, a, ...)
    if (queryItem.length < 3) return;

    const matchesInActivityLabel = findQueryInActivityLabel(queryItem);
    const matchesInActivityKeywords = findQueryInActivityKeywords(queryItem);

    // give a weight to each option depending on the matching (label VS keyword)
    matchesInActivityLabel.forEach(({ activity }: ActivityType) => {
      activityRelevancyScoring[activity] = activityRelevancyScoring[activity] || 0;
      activityRelevancyScoring[activity] += RELEVANCY_FACTOR_MATCH_ACTIVITY_LABEL;
    });

    matchesInActivityKeywords.forEach(({ activity }: ActivityType) => {
      activityRelevancyScoring[activity] = activityRelevancyScoring[activity] || 0;
      activityRelevancyScoring[activity] += RELEVANCY_FACTOR_MATCH_ACTIVITY_KEYWORD;
    });

    queryMatches.push(...matchesInActivityLabel, ...matchesInActivityKeywords);
  });

  // create, order and slice a tuple list based on the relevancy object
  let orderedActivitiesRelevancyScoring: Array<[string, number]> = [];

  for (let activityLabel in activityRelevancyScoring) {
    orderedActivitiesRelevancyScoring.push([activityLabel, activityRelevancyScoring[activityLabel]]);
  }

  return orderedActivitiesRelevancyScoring
    .sort((a, b) => b[1] - a[1])
    .slice(0, MAX_SUGGESTION_ITEMS_TO_DISPLAY)
    .reduce((accumulator: any, [label]) => {
      accumulator.push(label);

      return accumulator;
    }, []);
};

const StyledNavigationHeader = styled(Horizontal)`
  position: absolute;
  top: 0;
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.Blue.Lighter};

  & > a {
    position: absolute;
    left: ${Spacing.m}px;
  }

  & > ${Body} {
    justify-self: center;
  }

  @media (min-width: ${Breakpoints.laptopMinWidth}) {
  }
`;

const AnimationContainer = styled(Vertical)`
  align-items: center;
  display: none;
`;

type ExpandingInputType = {
  active: boolean;
  valueSelected: boolean;
  value: string;
};

const ExpandingInput = styled(Input)<ExpandingInputType>`
  ${({ active }) =>
    active &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      border-color: ${Colors.Primary.Regular};
    `};

  ${({ valueSelected }) =>
    valueSelected &&
    css`
      border-bottom-left-radius: ${BorderRadius.Button}px;
      border-bottom-right-radius: ${BorderRadius.Button}px;
      border-color: ${Colors.Primary.Regular};
      background-color: ${Colors.Primary.Light};
    `};

  ${({ value }) =>
    value === '' &&
    css`
      padding-left: 48px;
    `};
`;

const MagnifierContainer = styled(Vertical)`
  position: absolute;
  align-items: center;
  justify-content: center;
  left: ${Spacing.m}px;
`;

const CrossContainer = styled(Vertical)`
  position: absolute;
  align-items: center;
  justify-content: center;
  right: ${Spacing.m}px;
`;

const SearchInputContainer = styled(Horizontal)`
  position: relative;
  width: 100%;
  align-items: center;
`;

type SearchInputType = {
  type: string;
  name: string;
  placeholder: string;
  onChange: (e: any) => void;
  handleResetClick: (e: any) => void;
  value: string;
  active: boolean;
  valueSelected: boolean;
};

const SearchInput: React.FC<SearchInputType> = ({
  type,
  name,
  placeholder,
  onChange,
  handleResetClick,
  value,
  active,
  valueSelected
}) => {
  return (
    <SearchInputContainer>
      {!value && (
        <MagnifierContainer>
          <Magnifier />
        </MagnifierContainer>
      )}
      <ExpandingInput
        type={type}
        name={name}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        active={active}
        valueSelected={valueSelected}
      />
      {valueSelected && (
        <CrossContainer onClick={handleResetClick}>
          <Cross />
        </CrossContainer>
      )}
    </SearchInputContainer>
  );
};

type StyledButtonType = {
  secondary?: boolean;
  clicked?: boolean;
};

const StyledButton = styled(Button)<StyledButtonType>`
  height: 56px;
  font-size: ${Font.sizes.m};
  padding: ${Spacing.m}px ${Spacing.xxl}px;

  ${({ secondary, clicked }) =>
    secondary &&
    css`
      border: none;
      background-color: transparent;
      color: ${Colors.Primary.Regular};
      font-size: ${Font.sizes.s};
      animation: 1s linear ${clicked && Bounce} infinite;
    `};

  &:focus {
    outline-color: transparent;
  }

  & > svg {
    margin-left: ${Spacing.s}px;
  }
`;

const SuggestionContainer = styled(Vertical)`
  padding: ${Spacing.m}px;
  margin-top: 0;
  border: 1px solid ${Colors.Primary.Regular};
  border-top: none;
  border-bottom-left-radius: ${BorderRadius.Button}px;
  border-bottom-right-radius: ${BorderRadius.Button}px;
  background-color: ${Colors.White.Regular};

  & > hr {
    width: 100%;
    max-width: 80%;
    margin: ${Spacing.m}px auto;
    border: none;
    border-top: 1px solid ${Colors.Grey.Lighter};
  }
`;

const SuggestionItemContainer = styled(Horizontal)`
  align-items: center;
  justify-content: space-between;

  &:hover {
    cursor: pointer;
  }

  & > p {
    margin: 0;
    color: ${Colors.Secondary.Regular};
  }

  & > svg {
    flex-shrink: 0;
  }

  & + & {
    margin-top: ${Spacing.m}px;
  }
`;

const ActivityViewHeader: React.FC<{}> = () => {
  return (
    <StyledNavigationHeader>
      <Link to="/project/1">
        <Arrow size={24} rotation={180} color="Secondary.Regular" />
      </Link>
      <Body>
        <b>Votre projet</b> 2/4
      </Body>
    </StyledNavigationHeader>
  );
};

type SuggestionItemType = {
  label: string;
  query: string;
  onClick: () => void;
};

const SuggestionItem: React.FC<SuggestionItemType> = ({ label, query, onClick }) => {
  const queryItems = normalizeLabel(query).split(' ');
  const normalizedLabel = normalizeLabel(label);
  let queryMatchMapping: Array<[number, number]> = [];
  let formattedLabel = label;

  // add a "start - end" tuple in the match list
  queryItems.forEach((queryItem) => {
    const matchingIndex = normalizedLabel.indexOf(queryItem);

    if (matchingIndex >= 0) {
      queryMatchMapping.push([matchingIndex, matchingIndex + queryItem.length]);
    }
  });

  // sort the match list by starting index (query might not follow the label order)
  queryMatchMapping = queryMatchMapping.sort((a, b) => a[0] - b[0]);

  // use the match list to build an emphasised string, based on label to preserve capitalization and accents
  queryMatchMapping.forEach((matchMapping) => {
    // check which match we're dealing with
    const matchMappingIndex = queryMatchMapping.indexOf(matchMapping);

    // if this is the first match, reset the formatted label with the first part (unformatted, but might be empty) of the label
    if (matchMappingIndex === 0) {
      formattedLabel = label.substring(0, matchMapping[0]);
    }

    // append the highlighted part
    formattedLabel += `<b>${label.substring(matchMapping[0], matchMapping[1])}</b>`;

    // append the next "normal" part, which might be a bit of the label (several matches), or all the rest of the label
    if (matchMappingIndex + 1 < queryMatchMapping.length) {
      formattedLabel += label.substring(matchMapping[1], queryMatchMapping[matchMappingIndex + 1][0]);
    } else {
      formattedLabel += label.substring(matchMapping[1]);
    }
  });

  return (
    <SuggestionItemContainer onClick={onClick}>
      <p dangerouslySetInnerHTML={{ __html: formattedLabel }}></p>
      <AngleBracket size={16} rotation={180} />
    </SuggestionItemContainer>
  );
};

type ActivitySuggestionsType = {
  query: string;
  onSuggestionClick: (e: string) => void;
};

const ActivitySuggestions: React.FC<ActivitySuggestionsType> = ({ query, onSuggestionClick }) => {
  const [suggestions, setSuggestions] = useState<Array<string>>([]);

  useEffect(() => {
    setSuggestions(findActivitiesMatchingQuery(query));
  }, [query]);

  const handleClickDefaultOption = () => onSuggestionClick(capitalizeString(query));

  return (
    <SuggestionContainer>
      {suggestions.map((suggestion) => {
        const handleClick = () => onSuggestionClick(suggestion);

        return <SuggestionItem key={suggestion} onClick={handleClick} label={suggestion} query={query} />;
      })}
      <hr />
      <SuggestionItemContainer onClick={handleClickDefaultOption}>
        <p>
          Utiliser <b>{capitalizeString(query)}</b> comme description de mon activité
        </p>
        <AngleBracket size={16} rotation={180} />
      </SuggestionItemContainer>
    </SuggestionContainer>
  );
};

type ActivityFormData = {
  activity: string;
};

const ActivityView: React.FC<{}> = () => {
  const isLaptop = useMediaQuery({ query: `(min-width: ${Breakpoints.laptopMinWidth})` });
  const [selectedActivity, setSelectedActivity] = useState('');
  const [query, setQuery] = useState('');
  const [animate, setAnimate] = useState(false);
  const [initialHeight] = useState(window.innerHeight);
  const history = useHistory();
  const { handleSubmit, control, formState, setValue } = useForm<ActivityFormData>({
    defaultValues: { activity: '' }
  });

  const toggleTransition = () => setAnimate(!animate);

  const submitActivityForm = ({ activity }: ActivityFormData) => {
    trackActivitySelection(activity || 'Je ne sais pas encore');
    setTimeout(() => {
      toggleTransition();
      setTimeout(() => history.push('/project/3'), 200);
    }, 1000);
  };

  const handleQueryChange = (changeHandler: (e: string) => void) => (e: React.FormEvent<HTMLInputElement>) => {
    changeHandler(e.currentTarget.value);
    setQuery(e.currentTarget.value);

    if (selectedActivity) {
      setSelectedActivity('');
    }
  };

  const handleSuggestionClick = (value: string) => {
    setQuery('');
    setSelectedActivity(value);
    setValue('activity', value);
  };

  const handleResetClick = () => {
    setQuery('');
    setSelectedActivity('');
    setValue('activity', '');
  };

  const onResize = () => {
    document.documentElement.style.setProperty('overflow', 'auto');
    document
      .querySelector('meta[name=viewport]')
      ?.setAttribute('content', `height=${initialHeight}px, width=device-width, initial-scale=1.0`);
  };

  // Set a 250 debounce timer (avoid trigger computation if it changes too quickly)
  useEffect(() => {
    let debounceTimeout = setTimeout(() => query && findActivitiesMatchingQuery(query), 250);

    return () => {
      clearTimeout(debounceTimeout);
    };
  }, [query]);

  useEffect(() => {
    toggleTransition();
    window.addEventListener('resize', onResize);

    return () => window.removeEventListener('resize', onResize);
  }, []);

  return (
    <Container>
      <Header>{isLaptop ? <Logo /> : <ActivityViewHeader />}</Header>
      <Content padded={isLaptop}>
        {isLaptop && <ActivityViewHeader />}
        <CSSTransition classNames="slide" timeout={200} appear in={animate}>
          <AnimationContainer>
            <QuestionTitle>Quelle activité souhaitez-vous exercer ?</QuestionTitle>
            <Form onSubmit={handleSubmit(submitActivityForm)}>
              <Controller
                name="activity"
                control={control}
                render={({ onChange, value }) => (
                  <SearchInput
                    type="text"
                    name="activity"
                    placeholder="Ex : VTC, graphiste, plombier..."
                    onChange={handleQueryChange(onChange)}
                    handleResetClick={handleResetClick}
                    value={value}
                    active={!!query}
                    valueSelected={!!selectedActivity}
                  />
                )}
              />
              {query && <ActivitySuggestions query={query} onSuggestionClick={handleSuggestionClick} />}
              {selectedActivity ? (
                <StyledButton type="submit">
                  Valider ma réponse <Arrow color="White.Regular" size={20} />
                </StyledButton>
              ) : (
                <StyledButton secondary clicked={formState.isSubmitted || formState.isSubmitting}>
                  Je ne sais pas encore
                </StyledButton>
              )}
            </Form>
          </AnimationContainer>
        </CSSTransition>
      </Content>
    </Container>
  );
};

export default ActivityView;
