import React from 'react';
import styled, { keyframes } from 'styled-components';

import useWindowSize from '../../../hooks/useWindowSize';

type WavesProps = {
  offsetBottom?: number;
};

const SlideAndFadeIn = (offsetBottom: number) => keyframes`
  0% {
    bottom: -100%;
    opacity: 0;
  }
  100% {
    bottom: ${offsetBottom}px;
    opacity: 1;
  }
`;

const StyledSVG = styled.svg<WavesProps>`
  z-index: -1;
  position: absolute;
  bottom: ${({ offsetBottom }) => `${offsetBottom}px`};
  width: 100%;
  animation: ${({ offsetBottom }) => SlideAndFadeIn(offsetBottom || 0)} 1s ease-in-out;
`;

const Waves: React.FC<WavesProps> = ({ offsetBottom = 0 }) => {
  const size = useWindowSize();

  return (
    <StyledSVG
      width={size.width.toString()}
      height={((size.width / 375) * 202).toString()}
      viewBox="0 0 375 202"
      fill="none"
      offsetBottom={(size.width / 375) * 202 >= 0.75 * size.height ? ((size.width / 375) * 202) / -3 : offsetBottom}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M486.243 92.3389C357.857 -23.196 223.717 16.127 126.301 58.5768C28.8852 101.027 -112 58.5768 -112 58.5768L-112 260L486.243 260L486.243 92.3389Z"
        fill="#91C9FF"
        fillOpacity="0.15"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M486.105 123.643C486.105 123.643 486.416 50.7474 486.105 50.9359C374.203 -32.2777 254.566 0.239797 143.304 50.936C32.0423 101.632 -112 10.2504 -112 10.2504L-112 259L486.105 259L486.105 123.643Z"
        fill="#9ACCED"
        fillOpacity="0.15"
      />
    </StyledSVG>
  );
};

export default Waves;
