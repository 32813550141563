import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { Breakpoints, Colors, Font, Spacing } from '../../../styles/constants';

import { contentData } from '../../../data/content';
import { trackBookAppointment } from '../../../data/tracking/google_analytics';

import { CardList } from '../../../components/Content';
import { Logo, WavingHand } from '../../../components/Icon';
import { Intro } from '../../../components/Layout/Text';
import { Horizontal, Vertical } from '../../../components/Layout/Containers';
import Button from '../../../components/Layout/Button';

declare const __CALENDLY_URL__: string;

const StyledContentContainer = styled.div`
  height: 100%;
  width: 100%;
  overflow-x: hidden;
  padding: ${Spacing.l}px ${Spacing.xl}px 0 ${Spacing.xl}px;
`;

const LogoContainer = styled(Horizontal)`
  @media (min-width: ${Breakpoints.laptopMinWidth}) {
    justify-content: center;
    border-bottom: 1px solid ${Colors.Grey.Light};
    padding-bottom: ${Spacing.l}px;
  }
`;

const Header = styled(Vertical)`
  svg:not(.logo) {
    display: none;
  }

  @media (min-width: ${Breakpoints.laptopMinWidth}) {
    margin-left: ${Spacing.xl * -1}px;
    margin-right: ${Spacing.xl * -1}px;

    svg:not(.logo) {
      display: block;
      margin-right: ${Spacing.m}px;
      margin-left: ${Spacing.xl}px;
    }

    & > ${Horizontal} {
      align-items: center;
      margin-bottom: ${Spacing.m}px;
    }
  }
`;

const PadLess = styled.div`
  margin: 0 ${Spacing.xl * -1}px;
`;

const FloatingBanner = styled(Horizontal)`
  width: 100%;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 3;
  padding: ${Spacing.m}px;
  font-weight: ${Font.weight.bold};
  color: ${Colors.Secondary.Regular};
  background-color: ${Colors.White.Regular};
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);

  ${Button} {
    flex-shrink: 0;
    font-size: ${Font.sizes.m};
  }
`;

type ContentViewProps = {};

const ContentView: React.FC<ContentViewProps> = () => {
  const [hasScrolled, setHasScrolled] = useState(false);

  const handleScheduleClick = () => {
    trackBookAppointment();
    const win = window.open(__CALENDLY_URL__, '_blank');
    win?.focus();
  };

  useEffect(() => {
    const container = document.getElementById('content-container');

    const handleScroll = () => {
      if (container && container.scrollTop > 0 !== hasScrolled) {
        setHasScrolled(container.scrollTop > 0);
      } else {
        setHasScrolled(false);
      }
    };

    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <StyledContentContainer id="content-container">
      <Header>
        <LogoContainer>
          <Logo />
        </LogoContainer>
        <Horizontal>
          <WavingHand size={50} />
          <Intro align="left">Bienvenue, nous avons sélectionné ce contenu selon votre profil.</Intro>
        </Horizontal>
      </Header>
      <PadLess>
        {contentData.map((contentSection, index) => (
          <CardList {...contentSection} key={contentSection.listTitle} listIndex={index + 1} />
        ))}
      </PadLess>
      {hasScrolled && __CALENDLY_URL__?.startsWith('https://calendly.com/') && (
        <FloatingBanner>
          Besoin d’accompagnement sur votre projet ?<Button onClick={handleScheduleClick}>Contacter un expert</Button>
        </FloatingBanner>
      )}
    </StyledContentContainer>
  );
};

export default ContentView;
