import React from 'react';
import styled from 'styled-components';

import { ContentItemType } from '../../../data/content';

import { BorderRadius } from '../../../styles/constants';

import { Vertical } from '../../../components/Layout/Containers';
import { QuestionTitle } from '../../../components/Layout/Title';
import { Body } from '../../../components/Layout/Text';

const VideoContentTitle = styled(QuestionTitle)`
  width: 100%;
  text-align: left;
  margin-top: 0;
  margin-bottom: 16px;
  max-width: unset !important;
`;

const VideoContentIframe = styled.iframe`
  max-width: 100%;
  min-height: 400px;
  border-radius: ${BorderRadius.Card}px;
  overflow: hidden;
`;

const VideoContentText = styled(Body)`
  margin: 0;
  margin-top: 16px;
  text-align: left;
  max-width: unset !important;
`;

type VideoContentType = {
  content: ContentItemType;
};

const VideoContent: React.FC<VideoContentType> = ({ content }) => (
  <Vertical>
    <VideoContentTitle>{content.title}</VideoContentTitle>
    <VideoContentIframe
      src={content.redirectUrl}
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    ></VideoContentIframe>
    <VideoContentText>{content.description}</VideoContentText>
  </Vertical>
);

export default VideoContent;
