import React from 'react';

type EmployedProps = {
  size?: number;
};

const Employed: React.FC<EmployedProps> = ({ size = 32 }) => (
  <svg width={size} height={size} viewBox="0 0 30 27" fill="none">
    <path
      d="M19.7364 1.33113C19.6877 1.29398 19.2368 0.958535 18.4335 0.635742C17.3987 0.219844 16.2113 0 14.9998 0C13.7882 0 12.6009 0.219844 11.5659 0.635742C10.7627 0.958535 10.3118 1.29398 10.263 1.33119C10.0185 1.5177 9.87492 1.80768 9.87492 2.11523V3.66217L10.8609 4.18951L11.8469 3.66217V2.67357C12.388 2.39742 13.4701 1.97191 14.9997 1.97191C16.5294 1.97191 17.6114 2.39742 18.1525 2.67357V3.66211L19.1385 4.18945L20.1245 3.66211V2.11523C20.1246 1.80762 19.9811 1.51764 19.7364 1.33113Z"
      fill="#D1E9FF"
    />
    <path d="M9.87498 3.66217V4.75084L10.861 5.27819L11.847 4.75084V3.66217H9.87498Z" fill="#D1E9FF" />
    <path d="M20.1246 3.66217H18.1526V4.75084L19.1386 5.27819L20.1246 4.75084V3.66217Z" fill="#D1E9FF" />
    <path
      d="M0.776199 15.5975V24.3568C0.776199 25.3967 1.61919 26.2397 2.65905 26.2397H27.341C28.3808 26.2397 29.2238 25.3967 29.2238 24.3568V15.5975H0.776199Z"
      fill="#0083FF"
    />
    <path
      d="M0.776199 14.5089V15.5976C2.34042 16.8396 6.47456 19.2505 15 19.2505C23.5255 19.2505 27.6596 16.8396 29.2238 15.5975V14.5088H0.776199V14.5089Z"
      fill="#68B6FF"
    />
    <path
      d="M0.694453 13.8819C2.19357 15.1024 6.32197 17.6004 15 17.6004C23.678 17.6004 27.8064 15.1024 29.3055 13.8819C29.745 13.5241 30 12.4606 30 11.8939V6.63369C30 5.59383 29.157 4.75084 28.1171 4.75084H1.88285C0.842988 4.75084 0 5.59383 0 6.63369V11.8939C0 12.4606 0.255 13.5241 0.694453 13.8819Z"
      fill="#68B6FF"
    />
    <path
      d="M29.3055 13.3545C27.8064 14.575 23.678 17.0731 15 17.0731C6.32197 17.0731 2.19363 14.575 0.694453 13.3545C0.255 12.9967 0 12.4606 0 11.8939V12.9827C0 13.5493 0.255 14.0855 0.694453 14.4432C2.19357 15.6637 6.32197 18.1618 15 18.1618C23.678 18.1618 27.8064 15.6637 29.3055 14.4432C29.745 14.0855 30 13.5493 30 12.9827V11.8939C30 12.4606 29.745 12.9967 29.3055 13.3545Z"
      fill="#0083FF"
    />
    <path
      d="M14.9998 19.9738C15.9739 19.9738 16.7635 19.2505 16.7635 17.6828V16.6134C16.7635 16.2668 16.4825 15.9858 16.1359 15.9858H13.8637C13.5171 15.9858 13.2361 16.2669 13.2361 16.6134V17.683C13.2361 19.2505 14.0258 19.9738 14.9998 19.9738Z"
      fill="#D1E9FF"
    />
    <path
      d="M14.9998 19.4464C14.0258 19.4464 13.2361 18.6568 13.2361 17.6828V18.7715C13.2361 19.7456 14.0258 20.5352 14.9998 20.5352C15.9739 20.5352 16.7635 19.7456 16.7635 18.7715V17.6828C16.7635 18.6568 15.9739 19.4464 14.9998 19.4464Z"
      fill="#D1E9FF"
    />
  </svg>
);

export default Employed;
