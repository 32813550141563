import React from 'react';

type MagnifierProps = {
  size?: number;
};

const Magnifier: React.FC<MagnifierProps> = ({ size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 20 20" fill="none">
    <path
      d="M8.36667 16.1366C10.1 16.1366 11.7444 15.6368 13.1556 14.6706L18.1222 19.6571C18.3333 19.8792 18.6111 19.9902 18.9111 19.9902C19.1889 19.9902 19.4667 19.8903 19.6667 19.7015C20.1 19.3017 20.1111 18.6353 19.7 18.2133L14.7667 13.2602C16.0333 11.8053 16.7333 9.97291 16.7333 8.07383C16.7333 3.62045 12.9778 0 8.36667 0C3.75556 0 0 3.62045 0 8.07383C0 12.5161 3.75556 16.1366 8.36667 16.1366ZM8.36667 2.11008C11.7778 2.11008 14.5444 4.78655 14.5444 8.07383C14.5444 11.3611 11.7667 14.0376 8.36667 14.0376C4.95556 14.0376 2.18889 11.3611 2.18889 8.07383C2.18889 4.78655 4.95556 2.11008 8.36667 2.11008Z"
      fill="#2D3C56"
    />
  </svg>
);

export default Magnifier;
