import { Zaius } from '@zaiusinc/web-sdk';

declare const __ENV__: string;
declare const __ZAIUS_TRACKER_ID__: string; // Fetched from env variables at build time, can be found in Administration -> Data Management -> Integrations page

export const initializeZaius = () => {
  Zaius.initialize({ trackerId: __ZAIUS_TRACKER_ID__ });
};

/**
 * See https://docs.zaius.com/hc/en-us/articles/360015100114-Standard-event-definitions
 * @param {Object} additionalFields - which event data should be sent to Zaius, apart from the `event_type` (which is hardcoded to `MyLegalstart`)
 */
export const sendZaiusEvent = (additionalFields: object) => {
  if (__ENV__ === 'production') {
    Zaius.event('MyLegalstart', additionalFields);
  } else {
    console.groupCollapsed('Zaius');
    console.info('[DEV] Avoid sending dev data to Zaius');
    console.info('Would have sent an event as follows:', { type: 'MyLegalstart', ...additionalFields });
    console.groupEnd();
  }
};

export const addUserToList = (email: string) => {
  if (__ENV__ === 'production') {
    Zaius.subscribe(
      { list_id: 'save-mylegalstart', email: email },
      () => {},
      () => {}
    );
  } else {
    console.groupCollapsed('Zaius');
    console.info('[DEV] Avoid sending dev data to Zaius');
    console.info(`Would have added the user ${email} to the list 'save-mylegalstart'`);
    console.groupEnd();
  }
};
