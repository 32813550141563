import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router';

import { Breakpoints } from '../../styles/constants';
import { sendPageView } from '../../data/tracking/google_analytics';
import { Waves } from '../Icon';

const StyledLayout = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  @media (max-width: ${Breakpoints.mobileMaxWidth}) {
    flex-direction: column;
    max-height: 100%;
    height: auto;
  }
`;

type LayoutProps = {};

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    sendPageView(location.pathname);
  }, [location]);

  return (
    <StyledLayout>
      {children}
      <Waves />
    </StyledLayout>
  );
};

export default Layout;
