import React from 'react';

type MedalProps = {
  size?: number;
};

const Medal: React.FC<MedalProps> = ({ size = 32 }) => (
  <svg width={size} height={size} viewBox="0 0 32 32" fill="none">
    <path
      d="M15.9716 25.411C22.9887 25.411 28.6771 19.7226 28.6771 12.7055C28.6771 5.68845 22.9887 0 15.9716 0C8.95457 0 3.26611 5.68845 3.26611 12.7055C3.26611 19.7226 8.95457 25.411 15.9716 25.411Z"
      fill="#006EFF"
    />
    <path
      d="M5.67878 20.1382L1.30713 27.7161L6.40949 27.4453L8.7276 31.9996L12.7717 24.9886C9.87406 24.239 7.38587 22.4941 5.67878 20.1382Z"
      fill="#2D3C56"
    />
    <path
      d="M26.2898 20.1006C24.5954 22.4628 22.1072 24.214 19.2158 24.9762L23.2725 31.9998L25.5906 27.4455L30.693 27.7163L26.2898 20.1006Z"
      fill="#2D3C56"
    />
    <path
      d="M15.9717 21.915C21.0579 21.915 25.1811 17.7918 25.1811 12.7055C25.1811 7.6193 21.0579 3.49609 15.9717 3.49609C10.8854 3.49609 6.76221 7.6193 6.76221 12.7055C6.76221 17.7918 10.8854 21.915 15.9717 21.915Z"
      fill="#68B6FF"
    />
    <path
      d="M22.2835 11.7101C22.567 11.4329 22.4095 10.9479 22.0189 10.8912L18.3843 10.362C18.2268 10.3368 18.0945 10.2424 18.0252 10.0975L16.4 6.80298C16.2237 6.44393 15.7134 6.44393 15.537 6.80298L13.9182 10.0975C13.8489 10.2361 13.7103 10.3368 13.5591 10.362L9.92445 10.8912C9.5339 10.9479 9.37642 11.4329 9.65988 11.7101L12.2867 14.2738C12.4 14.3872 12.4504 14.5447 12.4252 14.6959L11.8079 18.3116C11.7386 18.7022 12.1544 19.0046 12.5071 18.8156L15.7575 17.1085C15.8961 17.0329 16.0662 17.0329 16.2048 17.1085L19.4552 18.8156C19.8079 18.9983 20.2174 18.7022 20.1544 18.3116L19.5307 14.6959C19.5056 14.5384 19.5559 14.3809 19.6693 14.2738L22.2835 11.7101Z"
      fill="white"
    />
  </svg>
);

export default Medal;
