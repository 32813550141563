import { ColorMap } from './styles/constants';

type ordinalObject = {
  order: number;
};

export const sortObjectByOrder = (itemA: ordinalObject, itemB: ordinalObject) => itemA.order - itemB.order;

const getAttribute = (object: any = {}, key: string) => object[key];
export const getColor = (object: ColorMap, path: string) => path.split('.').reduce(getAttribute, object);
