import React from 'react';

type UnemployedProps = {
  size?: number;
};

const Unemployed: React.FC<UnemployedProps> = ({ size = 32 }) => (
  <svg width={size} height={size} viewBox="0 0 30 30" fill="none">
    <path
      d="M28.434 29.412L28.3725 29.4739C27.7277 30.1241 26.7144 30.1241 26.0696 29.4739L20.9416 24.3346C20.7267 24.1178 20.7267 23.8082 20.9416 23.5915L22.5691 21.9506C22.784 21.7339 23.0911 21.7339 23.306 21.9506L28.4033 27.09C29.0788 27.7092 29.0788 28.7619 28.434 29.412Z"
      fill="#0083FF"
    />
    <path
      d="M28.434 27.09L28.3725 27.0281C28.3111 27.1519 28.219 27.2757 28.0962 27.3996L28.0348 27.4615C27.3899 28.1117 26.3766 28.1117 25.7318 27.4615L21.4022 23.1271L20.9416 23.5915C20.7267 23.8082 20.7267 24.1178 20.9416 24.3345L26.0389 29.4739C26.6837 30.1241 27.697 30.1241 28.3418 29.4739L28.4033 29.412C29.0788 28.7618 29.0788 27.7092 28.434 27.09Z"
      fill="#0083FF"
    />
    <path
      d="M29.0788 20.5884H0.890481C0.399181 20.5884 0 20.1859 0 19.6906V2.32201H29.9693V19.7215C29.9693 20.1859 29.5701 20.5884 29.0788 20.5884Z"
      fill="#F1F1F1"
    />
    <path
      d="M25.2098 2.32201V19.6906C25.2098 20.1859 24.8107 20.6194 24.2887 20.6194H29.0481C29.5394 20.6194 29.9693 20.2169 29.9693 19.6906V2.32201H25.2098Z"
      fill="#F1F1F1"
    />
    <path
      d="M14.9232 26.2231C20.5874 26.2231 25.1791 21.5935 25.1791 15.8825C25.1791 10.1715 20.5874 5.54183 14.9232 5.54183C9.25907 5.54183 4.66736 10.1715 4.66736 15.8825C4.66736 21.5935 9.25907 26.2231 14.9232 26.2231Z"
      fill="#F1F1F1"
    />
    <path
      d="M7.67655 8.54495C4.45239 11.7957 3.80756 16.6874 5.80347 20.5884H24.0737C26.0389 16.6874 25.4248 11.7957 22.2006 8.54495C18.1781 4.52014 11.6684 4.52014 7.67655 8.54495Z"
      fill="#F1F1F1"
    />
    <path
      d="M12.0675 5.91337C14.3398 6.16105 16.5506 7.15177 18.2702 8.88553C22.2927 12.9413 22.2927 19.4739 18.2702 23.5296C17.0112 24.799 15.4759 25.6968 13.8792 26.1612C16.8577 26.4708 19.9283 25.4801 22.2006 23.1891C26.2231 19.1333 26.2231 12.6007 22.2006 8.54497C19.437 5.78953 15.5373 4.92265 12.0675 5.91337Z"
      fill="#F1F1F1"
    />
    <path
      d="M12.0675 5.91337C14.3398 6.16105 16.5506 7.15177 18.2702 8.88553C21.4022 12.0744 22.0778 16.7494 20.3275 20.5884H24.0737C26.0389 16.6875 25.4248 11.7958 22.2006 8.54497C19.437 5.78953 15.5373 4.92265 12.0675 5.91337Z"
      fill="#F1F1F1"
    />
    <path
      d="M22.784 23.8083C18.4544 28.1736 11.392 28.1736 7.06242 23.8083C2.73284 19.4119 2.73284 12.3221 7.06242 7.95672C11.392 3.59136 18.4544 3.59136 22.784 7.95672C27.1136 12.3221 27.1136 19.4119 22.784 23.8083ZM8.25996 9.16416C4.57521 12.8794 4.57521 18.8856 8.25996 22.6008C11.9447 26.316 17.9017 26.316 21.5865 22.6008C25.2712 18.8856 25.2712 12.8794 21.5865 9.16416C17.9017 5.44896 11.9447 5.44896 8.25996 9.16416Z"
      fill="#68B6FF"
    />
    <path
      d="M14.3091 4.67495C16.9498 4.82975 19.5599 5.91335 21.5558 7.92575C25.8854 12.2911 25.8854 19.4119 21.5558 23.7773C19.5292 25.8206 16.9498 26.9042 14.3091 27.0281C17.349 27.1829 20.4504 26.0993 22.784 23.7773C27.1136 19.4119 27.1136 12.2911 22.784 7.92575C20.4811 5.60375 17.3797 4.52015 14.3091 4.67495Z"
      fill="#68B6FF"
    />
    <path
      d="M11.085 16.5946C11.085 17.6782 10.5015 18.3283 9.3347 18.3283C7.95292 18.3283 7.67656 17.3995 7.67656 16.8732C7.67656 16.4398 7.8608 16.1611 8.22927 16.1611C8.65916 16.1611 8.75128 16.5017 8.75128 16.8422C8.75128 17.1828 8.90481 17.4305 9.30399 17.4305C9.67246 17.4305 9.88741 17.1518 9.88741 16.5946V13.5605C9.88741 13.2199 10.1024 12.9722 10.4708 12.9722C10.8393 12.9722 11.0542 13.2199 11.0542 13.5605L11.085 16.5946Z"
      fill="#0083FF"
    />
    <path
      d="M14.4933 12.9413C16.1515 12.9413 17.0727 14.0559 17.0727 15.5729C17.0727 17.028 16.2436 18.3284 14.4933 18.3284C12.6817 18.3284 11.914 17.1209 11.914 15.5729C11.914 14.0559 12.8352 12.9413 14.4933 12.9413ZM14.4933 17.3996C15.5373 17.3996 15.9058 16.5636 15.9058 15.6039C15.9058 14.6131 15.4452 13.932 14.4933 13.932C13.5414 13.932 13.0808 14.6441 13.0808 15.6039C13.0808 16.5636 13.4186 17.3996 14.4933 17.3996Z"
      fill="#0083FF"
    />
    <path
      d="M17.9324 13.7463C17.9324 13.2199 18.2088 13.0651 18.608 13.0651H20.1433C21.0031 13.0651 22.0164 13.2509 22.0164 14.3964C22.0164 14.8299 21.7707 15.2323 21.3101 15.4491C21.8628 15.6039 22.2006 15.9754 22.2006 16.6875C22.2006 17.7711 21.3408 18.2045 20.3275 18.2045H18.6387C18.2395 18.2045 17.9631 18.0497 17.9631 17.5234L17.9324 13.7463ZM19.0686 15.1704H20.0205C20.5732 15.1704 20.8188 14.9537 20.8188 14.5512C20.8188 14.0559 20.4196 13.932 19.9898 13.932H19.0686V15.1704ZM19.0686 17.3067H20.0512C20.5118 17.3067 21.0031 17.2138 21.0031 16.6255C21.0031 16.0063 20.4503 15.9135 19.8976 15.9135H19.0686V17.3067Z"
      fill="#0083FF"
    />
    <path
      d="M0.890481 0H29.1095C29.6008 0 30 0.40248 30 0.89784V3.62232H0V0.89784C0 0.40248 0.399181 0 0.890481 0Z"
      fill="#0083FF"
    />
    <path d="M25.2098 3.62232H29.9693V0.9288C29.9693 0.43344 29.5701 0 29.0481 0H25.2098V3.62232Z" fill="#0083FF" />
    <path
      d="M2.21083 2.44585C2.53304 2.44585 2.79425 2.18249 2.79425 1.85761C2.79425 1.53274 2.53304 1.26937 2.21083 1.26937C1.88862 1.26937 1.62741 1.53274 1.62741 1.85761C1.62741 2.18249 1.88862 2.44585 2.21083 2.44585Z"
      fill="#F1F1F1"
    />
    <path
      d="M4.20674 2.44585C4.52895 2.44585 4.79016 2.18249 4.79016 1.85761C4.79016 1.53274 4.52895 1.26937 4.20674 1.26937C3.88453 1.26937 3.62332 1.53274 3.62332 1.85761C3.62332 2.18249 3.88453 2.44585 4.20674 2.44585Z"
      fill="#F1F1F1"
    />
    <path
      d="M6.23335 2.44585C6.55556 2.44585 6.81677 2.18249 6.81677 1.85761C6.81677 1.53274 6.55556 1.26937 6.23335 1.26937C5.91114 1.26937 5.64993 1.53274 5.64993 1.85761C5.64993 2.18249 5.91114 2.44585 6.23335 2.44585Z"
      fill="#F1F1F1"
    />
  </svg>
);

export default Unemployed;
