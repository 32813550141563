import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';

import { BorderRadius, Breakpoints, Colors, Spacing } from '../../styles/constants';

import { contentData, ContentItemType, ContentSectionType } from '../../data/content';

import { Container, Content } from '../../components/Layout/Page/V2';
import { Horizontal } from '../../components/Layout/Containers';
import { Arrow } from '../../components/Icon';

import VideoContent from './Video';

const NavButton = styled(Horizontal)`
  height: 56px;
  flex-shrink: 0;
  align-items: center;
  align-self: flex-start;
  padding: ${Spacing.m}px ${Spacing.l}px;
`;

const CardContent = styled(Content)`
  background-color: ${Colors.White.Regular};
  box-shadow: 0px 0px 40px rgba(45, 60, 86, 0.3);
  max-width: 90%;
  border-radius: ${BorderRadius.Card}px;
  padding: ${Spacing.m}px;
  margin-bottom: 5vw; // same as the horizontal space
`;

type ContentRouteParams = {
  contentId: string;
};

const ContentView: React.FC<{}> = () => {
  const [content, setContent] = useState<ContentItemType | null>(null);
  const { contentId } = useParams<ContentRouteParams>();
  const history = useHistory();
  const isLaptop = useMediaQuery({ query: `(min-width: ${Breakpoints.laptopMinWidth})` });

  const handlePrevNavigationButtonClick = () => {
    history.push('/content');
  };

  useEffect(() => {
    if (contentId) {
      const itemSection = contentData.find(({ contentCardList }: ContentSectionType) => {
        return contentCardList.find((item) => item.id === contentId);
      });
      const item = itemSection?.contentCardList.find((item) => item.id === contentId);

      if (item) {
        setContent(item);
      }
    }
  }, [contentId]);

  return (
    <Container scrollable>
      <NavButton onClick={handlePrevNavigationButtonClick}>
        <Arrow size={16} rotation={180} />
      </NavButton>
      <CardContent padded={isLaptop}>{content && <VideoContent content={content} />}</CardContent>
    </Container>
  );
};

export default ContentView;

export { default as ContentSummaryView } from './Summary';
