import React from 'react';

type LawHammerProps = {
  size?: number;
};

const LawHammer: React.FC<LawHammerProps> = ({ size = 32 }) => (
  <svg width={size} height={size} viewBox="0 0 30 27" fill="none">
    <path
      d="M12.0507 15.4992L10.5729 14.0214L0.51248 23.3084C-0.152324 23.9222 -0.173183 24.9657 0.466601 25.6054C1.10638 26.2452 2.14988 26.2244 2.76359 25.5596L12.0507 15.4992Z"
      fill="#0083FF"
    />
    <path
      d="M2.71918 25.606C2.10061 26.2246 1.0911 26.2298 0.466781 25.6055C0.282094 25.4208 0.15272 25.2027 0.0782471 24.9707C0.630023 25.1502 1.25955 25.0189 1.69485 24.5837C1.71014 24.5684 1.72537 24.5521 1.7402 24.5364L11.0275 14.4758L12.0509 15.4992L2.76359 25.5597C2.74877 25.5754 2.734 25.5912 2.71918 25.606Z"
      fill="#0083FF"
    />
    <path d="M13.5061 10.5196L10.103 14.579L11.4932 15.9691L15.5525 12.5661L13.5061 10.5196Z" fill="#0083FF" />
    <path
      d="M13.5061 10.5196L12.4065 11.8314L12.7364 12.1613C12.9793 12.4042 12.9793 12.7979 12.7364 13.0407L10.6506 15.1265L11.4932 15.9691L15.5525 12.5661L13.5061 10.5196Z"
      fill="#0083FF"
    />
    <path
      d="M10.6666 16.755L9.31711 15.4056C8.92699 15.0155 8.92699 14.3829 9.31711 13.9928C9.70722 13.6027 10.3398 13.6027 10.7299 13.9928L12.0794 15.3422C12.4695 15.7324 12.4695 16.3649 12.0794 16.755C11.6893 17.1452 11.0567 17.1452 10.6666 16.755Z"
      fill="#68B6FF"
    />
    <path
      d="M12.079 16.755C11.8837 16.9502 11.6279 17.0476 11.373 17.0476C11.1172 17.0476 10.8614 16.9502 10.6662 16.755L9.6433 15.7321C9.83853 15.9274 10.0943 16.0248 10.3497 16.0242C10.6051 16.0247 10.8609 15.9273 11.0561 15.7321C11.4465 15.3417 11.446 14.7093 11.0561 14.3193L12.079 15.3422C12.4694 15.7327 12.4694 16.3646 12.079 16.755Z"
      fill="#68B6FF"
    />
    <path d="M15.2694 1.24167L18.2658 4.23803L13.5616 8.94221L10.5652 5.94585L15.2694 1.24167Z" fill="#68B6FF" />
    <path d="M20.1248 15.5099L17.1284 12.5135L21.8326 7.80932L24.8289 10.8057L20.1248 15.5099Z" fill="#68B6FF" />
    <path
      d="M9.72216 6.97844L9.6181 6.87438C9.22341 6.47969 9.22341 5.83979 9.6181 5.44505L14.7671 0.296015C15.1618 -0.0986717 15.8017 -0.0986717 16.1965 0.296015L16.3005 0.400077C16.6952 0.794764 16.6952 1.43466 16.3005 1.82941L11.1515 6.97844C10.7568 7.37313 10.1169 7.37313 9.72216 6.97844Z"
      fill="#0083FF"
    />
    <path
      d="M19.0937 16.3499L19.1977 16.454C19.5924 16.8487 20.2323 16.8487 20.6271 16.454L25.7761 11.3049C26.1708 10.9102 26.1708 10.2703 25.7761 9.8756L25.672 9.77154C25.2774 9.37685 24.6375 9.37685 24.2427 9.77154L19.0937 14.9206C18.6989 15.3153 18.6989 15.9552 19.0937 16.3499Z"
      fill="#0083FF"
    />
    <path d="M19.5662 3.13724L22.9311 6.50212L15.8263 13.6068L12.4615 10.242L19.5662 3.13724Z" fill="#68B6FF" />
    <path d="M14.121 11.9077L21.2257 4.80296L22.9288 6.50605L15.8241 13.6108L14.121 11.9077Z" fill="#68B6FF" />
    <path
      d="M19.5825 2.99577L18.7581 2.65874C18.4755 2.5432 18.1511 2.60847 17.9352 2.82439L12.1464 8.6132C11.9305 8.82912 11.8652 9.15344 11.9808 9.43603L12.3178 10.2604C12.4544 10.5947 12.8875 10.6819 13.1429 10.4266L19.7485 3.82083C20.0039 3.56548 19.9167 3.13241 19.5825 2.99577Z"
      fill="#0083FF"
    />
    <path
      d="M23.0763 6.48964L23.4134 7.31405C23.5289 7.59665 23.4636 7.92102 23.2477 8.13688L17.4589 13.9257C17.243 14.1416 16.9187 14.2069 16.6361 14.0913L15.8117 13.7543C15.4774 13.6177 15.3902 13.1846 15.6456 12.9293L22.2513 6.32352C22.5066 6.06817 22.9397 6.15536 23.0763 6.48964Z"
      fill="#0083FF"
    />
    <path
      d="M26.1281 22.6909H13.8539V20.9684C13.8539 20.6128 14.1422 20.3245 14.4978 20.3245H25.4843C25.8399 20.3245 26.1281 20.6128 26.1281 20.9684V22.6909Z"
      fill="#68B6FF"
    />
    <path
      d="M26.128 20.9685V22.6909H24.781V20.9685C24.781 20.6131 24.4926 20.3246 24.1371 20.3246H25.4841C25.8395 20.3246 26.128 20.6131 26.128 20.9685Z"
      fill="#68B6FF"
    />
    <path
      d="M28.8333 26.0718H11.1487C10.6796 26.0718 10.368 25.5863 10.5633 25.1598L11.1885 23.7948C11.7301 22.6124 12.9112 21.8542 14.2118 21.8542H25.7703C27.0708 21.8542 28.252 22.6124 28.7935 23.7948L29.4187 25.1598C29.614 25.5863 29.3023 26.0718 28.8333 26.0718Z"
      fill="#0083FF"
    />
    <path
      d="M28.8335 26.0718H27.1118C27.5806 26.0718 27.8922 25.5863 27.6971 25.1601L27.0719 23.7945C26.5304 22.6123 25.3489 21.8539 24.0489 21.8539H25.77C27.0706 21.8539 28.2521 22.6124 28.7936 23.7945L29.4188 25.1601C29.6139 25.5864 29.3023 26.0718 28.8335 26.0718Z"
      fill="#0083FF"
    />
    <path
      d="M27.8955 19.2583C27.7809 19.2583 27.6664 19.2144 27.5792 19.1268C27.4056 18.9521 27.4064 18.6697 27.5811 18.496L28.0637 18.0163C28.2385 17.8426 28.5208 17.8435 28.6945 18.0181C28.8681 18.1929 28.8673 18.4752 28.6926 18.6489L28.21 19.1287C28.123 19.2151 28.0092 19.2583 27.8955 19.2583Z"
      fill="#68B6FF"
    />
    <path
      d="M26.488 18.2609C26.4521 18.2609 26.4156 18.2565 26.3792 18.2473C26.1403 18.1874 25.9952 17.9452 26.0551 17.7063L26.2225 17.0389C26.2824 16.7999 26.5248 16.6549 26.7635 16.7148C27.0024 16.7747 27.1475 17.017 27.0876 17.2559L26.9202 17.9232C26.8695 18.1257 26.6877 18.2609 26.488 18.2609Z"
      fill="#68B6FF"
    />
    <path
      d="M28.885 20.6716C28.6842 20.6716 28.502 20.5351 28.4522 20.3315C28.3936 20.0922 28.5401 19.8508 28.7794 19.7923L29.448 19.6288C29.6874 19.5702 29.9286 19.7167 29.9871 19.956C30.0457 20.1953 29.8992 20.4367 29.6599 20.4952L28.9913 20.6587C28.9557 20.6674 28.9201 20.6716 28.885 20.6716Z"
      fill="#68B6FF"
    />
  </svg>
);

export default LawHammer;
