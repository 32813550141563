import styled, { css } from 'styled-components';

import { BorderRadius, Colors, Font, Spacing } from '../../../styles/constants';

export const ButtonStyle = css`
  background-color: ${Colors.Primary.Regular};
  border-radius: ${BorderRadius.Button}px;
  padding: ${Spacing.m}px ${Spacing.l}px;
  color: ${Colors.White.Regular};
  text-decoration: none;
  font-weight: ${Font.weight.bold};
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
`;

const Button = styled.button`
  ${ButtonStyle};
`;

export default Button;
