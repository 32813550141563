import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

import { BorderRadius, Breakpoints, Colors, Spacing } from '../../../styles/constants';

import { Horizontal } from '../Containers';
import { Body } from '../Text';

type Item = {
  label: string;
  value: string;
  icon: JSX.Element;
};

type ItemList = Item[];

const ItemListContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media (min-width: ${Breakpoints.laptopMinWidth}) {
    flex-direction: row;
    flex-wrap: wrap;
    max-width: ${2 * (260 + 2 * Spacing.s)}px; // 2 item elements width + margins

    // flex pseudo-element to align last item if the last row is not full
    &::after {
      content: '';
      flex: auto;
    }
  }
`;

type ItemContainerProps = {
  selected: boolean;
};

const ItemContainer = styled(Horizontal)<ItemContainerProps>`
  height: 56px;
  max-width: 260px;
  width: 100%;
  margin: ${Spacing.s}px;
  border: 1px solid ${({ selected }) => (selected ? Colors.Primary.Regular : Colors.Grey.Light)};
  border-radius: ${BorderRadius.Button}px;
  background-color: ${({ selected }) => (selected ? Colors.Primary.Light : Colors.White.Regular)};
  align-items: center;
  padding: ${Spacing.s}px ${Spacing.m}px;
  box-shadow: ${({ selected }) => (selected ? '0px 0px 10px rgba(22, 169, 116, 0.2)' : 'none')};
  ${({ selected }) => selected && 'transform: scale(1.05);'};
  transition: all 0.4s ease-in-out;

  & > svg {
    margin-right: ${Spacing.m}px;
  }

  &:hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: transform 0.4s ease-in-out;
  }
`;

type ItemProps = {
  label: string;
  icon: JSX.Element;
  selected: boolean;
  onClick: () => void;
};

const Item: React.FC<ItemProps> = ({ label, icon, onClick, selected }) => {
  return (
    <ItemContainer onClick={onClick} selected={selected}>
      {icon}
      <Body>{label}</Body>
    </ItemContainer>
  );
};

type ItemListProps = {
  options: ItemList;
  onItemSelected: (itemValue: string) => void;
  onItemSelectedTimeout: number;
};

const ItemList: React.FC<ItemListProps> = ({ options, onItemSelected, onItemSelectedTimeout }) => {
  const [selectedItem, setSelectedItem] = useState('');

  useEffect(() => {
    let timeout: any;

    if (selectedItem) {
      timeout = setTimeout(() => onItemSelected(selectedItem), onItemSelectedTimeout);
    }

    return () => clearTimeout(timeout);
  }, [selectedItem]);

  return (
    <ItemListContainer>
      {options.map(({ label, icon, value }) => {
        const handleItemClick = () => setSelectedItem(value);

        return (
          <Item label={label} icon={icon} onClick={handleItemClick} selected={selectedItem === value} key={value} />
        );
      })}
    </ItemListContainer>
  );
};

export default ItemList;
