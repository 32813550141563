import React from 'react';

import { getColor } from '../../../helpers';

import { Colors } from '../../../styles/constants';

type AngleBracketProps = {
  color?: string;
  size?: number;
  rotation?: number;
};

const AngleBracket: React.FC<AngleBracketProps> = ({ color = 'Secondary.Regular', size = 32, rotation = 0 }) => (
  <svg width={size} height={size} viewBox="0 0 9 8" fill="none" style={{ transform: `rotate(${rotation}deg)` }}>
    <path
      d="M4.53553 7.53554L1 4L4.53553 0.464466"
      stroke={getColor(Colors, color) || Colors.Secondary.Regular}
      strokeLinejoin="round"
    />
  </svg>
);

export default AngleBracket;
