import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useMediaQuery } from 'react-responsive';
import { Link, useHistory } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { Breakpoints, Colors, Spacing } from '../../../styles/constants';

import { trackDelayClick } from '../../../data/tracking/google_analytics';

import { Arrow, Logo, MuscleFlex, PaperPlane, Plane, Rocket } from '../../../components/Icon';
import { QuestionTitle } from '../../../components/Layout/Title';
import { Body } from '../../../components/Layout/Text';
import { Container, Content, Header } from '../../../components/Layout/Page/V2';
import ItemList from '../../../components/Layout/ItemList';
import { Horizontal, Vertical } from '../../../components/Layout/Containers';

const StyledNavigationHeader = styled(Horizontal)`
  position: absolute;
  top: 0;
  width: 100%;
  height: 56px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.Blue.Lighter};

  & > a {
    position: absolute;
    left: ${Spacing.m}px;
  }

  & > ${Body} {
    justify-self: center;
  }
`;

const AnimationContainer = styled(Vertical)`
  align-items: center;
  display: none;
`;

const SITUATION_OPTIONS = [
  {
    label: 'D’ici un mois',
    value: 'less_than_a_month',
    icon: <Rocket />
  },
  {
    label: 'Entre 1 et 6 mois',
    value: 'between_1_and_6_months',
    icon: <Plane />
  },
  {
    label: 'Plus de 6 mois',
    value: 'more_than_6_months',
    icon: <PaperPlane />
  },
  {
    label: 'Je me suis déjà lancé',
    value: 'already_started',
    icon: <MuscleFlex />
  }
];

const DelayViewHeader: React.FC<{}> = () => {
  return (
    <StyledNavigationHeader>
      <Link to="/project/2">
        <Arrow size={24} rotation={180} color="Secondary.Regular" />
      </Link>
      <Body>
        <b>Votre projet</b> 3/4
      </Body>
    </StyledNavigationHeader>
  );
};

const DelayView: React.FC<{}> = () => {
  const isLaptop = useMediaQuery({ query: `(min-width: ${Breakpoints.laptopMinWidth})` });
  const [animate, setAnimate] = useState(false);
  const history = useHistory();

  const toggleTransition = () => setAnimate(!animate);

  const handleItemSelect = (itemValue: string) => {
    toggleTransition();
    trackDelayClick(itemValue);
    setTimeout(() => history.push('/project/4'), 100);
  };

  useEffect(() => {
    toggleTransition();
  }, []);

  return (
    <Container>
      <Header>{isLaptop ? <Logo /> : <DelayViewHeader />}</Header>
      <Content padded={isLaptop}>
        {isLaptop && <DelayViewHeader />}
        <CSSTransition classNames="slide" timeout={200} appear in={animate}>
          <AnimationContainer>
            <QuestionTitle>Dans quel délai envisagez-vous de lancer votre projet ?</QuestionTitle>
            <ItemList options={SITUATION_OPTIONS} onItemSelected={handleItemSelect} onItemSelectedTimeout={500} />
          </AnimationContainer>
        </CSSTransition>
      </Content>
    </Container>
  );
};

export default DelayView;
