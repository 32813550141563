import styled from 'styled-components';

import { BorderRadius, Breakpoints, Colors } from '../../../../styles/constants';

import { Vertical } from '../../Containers';
import { Intro, Body } from '../../Text';
import { QuestionTitle } from '../../Title';

// TODO : use constants for padding-top
type ContainerType = {
  scrollable?: boolean;
};

export const Container = styled(Vertical)<ContainerType>`
  height: 100%;
  max-height: 100%;
  width: 100%;
  overflow: ${({ scrollable }) => (scrollable ? 'auto' : 'hidden')};
  align-items: center;
  justify-content: ${({ scrollable }) => (scrollable ? 'flex-start' : 'center')};
  padding-top: ${({ scrollable }) => (scrollable ? '0' : '56px')};

  .slide-enter {
    display: flex;
    transform: translate(800px);
    opacity: 0.01;
  }

  .slide-enter-done {
    display: flex;
    transform: translate(0vw);
    transition: opacity 600ms ease-in-out, transform 400ms ease-in-out;
    opacity: 1;
  }

  .slide-exit {
    display: flex;
    transform: translate(0vw);
  }

  .slide-exit-active,
  .slide-exit-done {
    display: flex;
    transform: translate(-800px);
    transition: transform 400ms ease-in-out;
  }

  .stretch-out-exit {
    transition: all 600ms linear;
    max-height: 500px;

    & > * {
      transition: opacity 400ms ease-in-out;
      opacity: 0;
    }
  }

  .stretch-out-exit-active,
  .stretch-out-exit-done {
    max-width: 100%;
    width: 100%;
    max-height: 100%;
    height: 100%;
    position: absolute;
    margin-top: -80px;
    transition: all 600ms linear;
    background-color: ${Colors.White.Regular};

    & > * {
      transition: opacity 400ms ease-in-out;
      opacity: 0;
    }
  }

  @media (min-width: ${Breakpoints.laptopMinWidth}) {
    padding-top: 80px;
  }
`;

// TODO : use constants for height
export const Header = styled.header`
  position: absolute;
  top: 0;
  width: 100%;
  height: 56px;
  display: flex;
  align-items: flex-end;
  justify-content: center;

  @media (min-width: ${Breakpoints.laptopMinWidth}) {
    height: 80px;

    & > svg.logo {
      transform: scale(1.75);
      transform-origin: bottom;
    }
  }
`;

type ContentType = {
  padded?: boolean;
};

// TODO : constant-ify this (colors, sizing)
export const Content = styled(Vertical)<ContentType>`
  align-items: center;
  justify-content: center;
  position: relative;
  ${({ padded }) => padded && 'padding-top: 56px;'};

  ${Intro}, ${Body} {
    max-width: 320px;
  }

  ${QuestionTitle} {
    max-width: 320px;

    @media (min-width: ${Breakpoints.laptopMinWidth}) {
      max-width: 500px;
    }
  }

  @media (min-width: ${Breakpoints.laptopMinWidth}) {
    background-color: ${Colors.White.Regular};
    box-shadow: 0px 0px 40px rgba(45, 60, 86, 0.3);
    width: 80%;
    max-width: 800px;
    min-height: 500px;
    border-radius: ${BorderRadius.Card}px;
    overflow: hidden;
  }
`;
