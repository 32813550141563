import React from 'react';
import ReactDOM from 'react-dom';

import './styles/main.css'; // this import is needed for the build to actually generate the CSS bundle + copy fonts

import App from './app';

import { initializeZaius } from './data/tracking/zaius';

initializeZaius();

ReactDOM.render(<App />, document.getElementById('myls-app'));
