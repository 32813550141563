import React from 'react';
import { Colors } from '../../../styles/constants';

type GrowingIdeaProps = {
  size?: number;
};

const GrowingIdea: React.FC<GrowingIdeaProps> = ({ size = 32 }) => (
  <svg width={size} height={size} viewBox="0 0 80 80" fill="none">
    <path
      d="M37.1393 55.5309H39.9999V52.6702C39.9999 48.8743 36.9227 45.7971 33.1268 45.7971H30.8912C30.546 45.7971 30.2662 46.077 30.2662 46.4221V48.6577C30.2662 52.4537 33.3434 55.5309 37.1393 55.5309Z"
      fill={Colors.Primary.Regular}
      fillOpacity="0.6"
    />
    <path
      d="M39.2115 56.4211L35.1303 52.8075C34.6384 52.372 34.5928 51.6205 35.0282 51.1288C35.4636 50.6369 36.2153 50.5913 36.707 51.0267L40.7882 54.6403C41.2801 55.0758 41.3257 55.8273 40.8903 56.3191C40.4537 56.8123 39.7015 56.855 39.2115 56.4211Z"
      fill={Colors.Primary.Regular}
    />
    <path
      d="M42.8606 62.6666H40V59.806C40 56.0101 43.0772 52.9329 46.8731 52.9329H49.1087C49.4539 52.9329 49.7337 53.2127 49.7337 53.5579V55.7935C49.7337 59.5894 46.6566 62.6666 42.8606 62.6666Z"
      fill={Colors.Primary.Regular}
      fillOpacity="0.6"
    />
    <path
      d="M39.1095 63.4548C38.674 62.9629 38.7198 62.2115 39.2115 61.7761L43.2929 58.1625C43.7845 57.727 44.5361 57.7726 44.9717 58.2645C45.4072 58.7564 45.3615 59.5078 44.8697 59.9432L40.7882 63.5568C40.2984 63.9909 39.5464 63.9482 39.1095 63.4548Z"
      fill={Colors.Primary.Regular}
    />
    <path
      d="M37.1393 72.1809H39.9999V69.3201C39.9999 65.5242 36.9227 62.447 33.1268 62.447H30.8912C30.546 62.447 30.2662 62.7269 30.2662 63.072V65.3076C30.2662 69.1037 33.3434 72.1809 37.1393 72.1809Z"
      fill={Colors.Primary.Regular}
      fillOpacity="0.6"
    />
    <path
      d="M39.2115 73.0712L35.1303 69.4576C34.6384 69.0222 34.5928 68.2706 35.0282 67.7789C35.4636 67.287 36.2153 67.2414 36.707 67.6769L40.7882 71.2905C41.2801 71.7259 41.3257 72.4775 40.8903 72.9692C40.4537 73.4625 39.7015 73.5051 39.2115 73.0712Z"
      fill={Colors.Primary.Regular}
    />
    <path
      d="M40 80.0001C39.3431 80.0001 38.8108 79.4678 38.8108 78.8109V42.5667C38.8108 41.9098 39.3433 41.3774 40 41.3774C40.6569 41.3774 41.1894 41.9098 41.1894 42.5667V78.8107C41.1892 79.4678 40.6569 80.0001 40 80.0001Z"
      fill={Colors.Primary.Regular}
    />
    <path
      d="M62.1145 69.8734H64.9751V67.0128C64.9751 63.2168 61.8979 60.1396 58.102 60.1396H55.8663C55.5212 60.1396 55.2413 60.4195 55.2413 60.7646V63.0003C55.2413 66.7962 58.3185 69.8734 62.1145 69.8734Z"
      fill={Colors.Primary.Regular}
      fillOpacity="0.6"
    />
    <path
      d="M64.1341 70.7147L60.0385 66.6191C59.5741 66.1545 59.5741 65.4016 60.0385 64.937C60.503 64.4728 61.256 64.4728 61.7205 64.937L65.8161 69.0327C66.2805 69.4972 66.2805 70.2502 65.8161 70.7147C65.3517 71.1792 64.5988 71.1789 64.1341 70.7147Z"
      fill={Colors.Primary.Regular}
    />
    <path
      d="M64.9751 79.9998C64.3183 79.9998 63.7858 79.4674 63.7858 78.8106V64.0332C63.7858 63.3763 64.3181 62.844 64.9751 62.844C65.632 62.844 66.1645 63.3763 66.1645 64.0332V78.8106C66.1645 79.4674 65.6322 79.9998 64.9751 79.9998Z"
      fill={Colors.Primary.Regular}
    />
    <path
      d="M67.8358 64.0333H64.9752V61.1727C64.9752 57.3767 68.0524 54.2996 71.8483 54.2996H74.084C74.4291 54.2996 74.709 54.5794 74.709 54.9246V57.1602C74.7088 60.9561 71.6316 64.0333 67.8358 64.0333Z"
      fill={Colors.Primary.Regular}
      fillOpacity="0.6"
    />
    <path
      d="M64.0848 64.8216C63.6494 64.3297 63.695 63.5783 64.1869 63.1428L68.2683 59.5292C68.7598 59.0936 69.5114 59.1394 69.947 59.6313C70.3825 60.1231 70.3369 60.8745 69.845 61.31L65.7636 64.9236C65.2736 65.3578 64.5217 65.315 64.0848 64.8216Z"
      fill={Colors.Primary.Regular}
    />
    <path
      d="M17.8854 69.8734H15.0248V67.0128C15.0248 63.2168 18.102 60.1396 21.8979 60.1396H24.1335C24.4787 60.1396 24.7585 60.4195 24.7585 60.7646V63.0003C24.7585 66.7962 21.6813 69.8734 17.8854 69.8734Z"
      fill={Colors.Primary.Regular}
      fillOpacity="0.6"
    />
    <path
      d="M15.0248 71.063C15.329 71.063 15.6335 70.9469 15.8657 70.7147L19.9613 66.6191C20.4257 66.1545 20.4257 65.4016 19.9613 64.937C19.4968 64.4728 18.7438 64.4728 18.2793 64.937L14.1837 69.0327C13.7193 69.4972 13.7193 70.2502 14.1837 70.7147C14.416 70.9467 14.7204 71.063 15.0248 71.063Z"
      fill={Colors.Primary.Regular}
    />
    <path
      d="M15.0248 79.9998C15.6817 79.9998 16.2142 79.4674 16.2142 78.8106V64.0332C16.2142 63.3763 15.6818 62.844 15.0248 62.844C14.3679 62.844 13.8356 63.3763 13.8356 64.0332V78.8106C13.8354 79.4674 14.3678 79.9998 15.0248 79.9998Z"
      fill={Colors.Primary.Regular}
    />
    <path
      d="M12.164 64.0333H15.0246V61.1727C15.0246 57.3767 11.9475 54.2996 8.15152 54.2996H5.91589C5.57074 54.2996 5.29089 54.5794 5.29089 54.9246V57.1602C5.29089 60.9561 8.36808 64.0333 12.164 64.0333Z"
      fill={Colors.Primary.Regular}
      fillOpacity="0.6"
    />
    <path
      d="M15.0243 65.2225C15.353 65.2225 15.6801 65.087 15.9152 64.8216C16.3507 64.3297 16.3049 63.5783 15.8132 63.1428L11.7318 59.5292C11.2402 59.0936 10.4887 59.1394 10.053 59.6313C9.61756 60.1231 9.66319 60.8745 10.1551 61.31L14.2365 64.9236C14.4626 65.1241 14.744 65.2225 15.0243 65.2225Z"
      fill={Colors.Primary.Regular}
    />
    <path
      d="M39.9999 42.5664C36.9347 42.5664 34.4499 40.0816 34.4499 37.0164V35.4307H45.55V37.0164C45.5499 40.0816 43.065 42.5664 39.9999 42.5664Z"
      fill={Colors.Primary.Regular}
    />
    <path
      d="M40.7928 35.4307V37.0164C40.7928 39.2303 39.4962 41.1408 37.6213 42.0316C38.3424 42.3741 39.1485 42.5664 39.9999 42.5664C43.0651 42.5664 45.5499 40.0816 45.5499 37.0164V35.4307H40.7928Z"
      fill={Colors.Primary.Regular}
    />
    <path
      d="M54.6883 14.6881C54.6883 6.74477 48.3831 0.274306 40.5047 0.0083682C32.2645 0.269757 25.324 6.42118 25.3118 14.666C25.3048 19.4526 27.5876 23.706 31.1254 26.3924C33.5572 28.2388 35.0209 31.0867 35.0209 34.1399V34.2309H44.979V34.1399C44.979 31.0823 46.4508 28.236 48.8845 26.3848C52.4112 23.7021 54.6883 19.4612 54.6883 14.6881Z"
      fill="#F7E782"
    />
    <path
      d="M49.1294 3.18213C51.12 5.68869 52.3097 8.85994 52.3097 12.3095C52.3097 13.8306 52.0784 15.2976 51.6492 16.6773C50.6094 20.0203 48.6583 21.1667 46.5059 24.0063C46.0841 24.5629 45.6311 25.2143 45.1742 25.9706C42.9808 29.6013 39.0791 31.8523 34.8372 31.8523H34.7411C34.9241 32.5926 35.0211 33.3593 35.0211 34.1399V34.2309H44.9792V34.1399C44.9792 31.0823 46.4509 28.236 48.8847 26.3848C52.4115 23.7021 54.6884 19.461 54.6884 14.6879C54.6883 10.0265 52.5161 5.87291 49.1294 3.18213Z"
      fill="#F4DD46"
    />
    <path
      d="M46.6578 9.43692C43.6425 9.43692 41.1892 11.8902 41.1892 14.9057V15.0357H38.8106C38.8896 11.9719 36.3996 9.43677 33.342 9.43677C31.1426 9.43677 29.3534 11.2261 29.3534 13.4255C29.3534 15.6247 31.1426 17.4141 33.342 17.4141H36.432V33.0518C36.432 33.7086 36.9645 34.241 37.6214 34.241C38.2782 34.241 38.8106 33.7086 38.8106 33.0518V17.4144H41.1892V33.0521C41.1892 33.709 41.7215 34.2413 42.3784 34.2413C43.0353 34.2413 43.5678 33.709 43.5678 33.0521V17.4144H46.6579C48.8571 17.4144 50.6465 15.625 50.6465 13.4258C50.6467 11.2263 48.8573 9.43692 46.6578 9.43692ZM33.342 15.0358C32.4542 15.0358 31.732 14.3136 31.732 13.4258C31.732 12.538 32.4542 11.8157 33.342 11.8157C35.0796 11.8157 36.5123 13.2847 36.432 15.0358H33.342ZM46.6579 15.0358H43.5678C43.515 13.2897 44.894 11.8157 46.6579 11.8157C47.5457 11.8157 48.2679 12.538 48.2679 13.4258C48.2681 14.3135 47.5457 15.0358 46.6579 15.0358Z"
      fill="#F9EFAF"
    />
    <path
      d="M70.4687 79.9999H9.5312C8.87432 79.9999 8.34198 79.4676 8.34198 78.8107C8.34198 78.1538 8.87448 77.6213 9.5312 77.6213H70.4687C71.1256 77.6213 71.6581 78.1537 71.6581 78.8107C71.6581 79.4677 71.1256 79.9999 70.4687 79.9999Z"
      fill={Colors.Secondary.Regular}
    />
    <path
      d="M45.5499 37.8094H34.4499C33.1362 37.8094 32.0714 36.7444 32.0714 35.4308C32.0714 34.1172 33.1363 33.0522 34.4499 33.0522H45.5501C46.8638 33.0522 47.9287 34.1172 47.9287 35.4308C47.9285 36.7446 46.8637 37.8094 45.5499 37.8094Z"
      fill={Colors.Primary.Regular}
      fillOpacity="0.6"
    />
    <path
      d="M45.5499 33.0522H40.7927C42.1063 33.0522 43.1713 34.1172 43.1713 35.4308C43.1713 36.7444 42.1063 37.8094 40.7927 37.8094H45.5499C46.8635 37.8094 47.9285 36.7444 47.9285 35.4308C47.9285 34.1172 46.8637 33.0522 45.5499 33.0522Z"
      fill={Colors.Primary.Regular}
    />
  </svg>
);

export default GrowingIdea;
