import React from 'react';
import { useHistory } from 'react-router';
import styled from 'styled-components';

import { CardContentEnum } from '../../../data/content';
import { trackContentClick } from '../../../data/tracking/google_analytics';

import { BorderRadius, Breakpoints, Colors, Font, Spacing } from '../../../styles/constants';

import { Chrono, Play } from '../../Icon';
import { Horizontal, Vertical } from '../../Layout/Containers';
import { SmallLabel } from '../../Layout/Text';

const CardContainer = styled(Vertical)`
  max-width: 140px;
  scroll-snap-align: start;

  & + & {
    margin-left: ${Spacing.m}px;
  }

  @media (min-width: ${Breakpoints.laptopMinWidth}) {
    max-width: 200px;
  }
`;

type ImgContainerType = {
  src: string;
};

const ImgContainer = styled(Vertical)<ImgContainerType>`
  border-radius: ${BorderRadius.Card}px;
  height: 120px;
  width: 140px;
  position: relative;
  border: 0.5px solid rgba(45, 60, 86, 0.2);
  filter: drop-shadow(0px 0px 10px rgba(45, 60, 86, 0.15));
  background: center top / cover no-repeat url(${({ src }) => `"${src}"`});

  @media (min-width: ${Breakpoints.laptopMinWidth}) {
    width: 200px;
  }
`;

const TagsContainer = styled(Horizontal)`
  align-items: center;
  position: absolute;
  bottom: 0px;
  left: 0px;
  padding: ${Spacing.xs}px;
  max-width: 100%;
  flex-wrap: wrap;

  & > ${Horizontal} {
    padding: ${Spacing.xs}px ${Spacing.xs}px;
    border-radius: ${BorderRadius.Button}px;
    background-color: ${Colors.White.Regular};
    color: ${Colors.Secondary.Regular};
    font-size: 10px;
    font-weight: ${Font.weight.bold};
    flex-shrink: 0;
    margin: ${Spacing.xs}px;
    max-width: 100%;

    & > svg {
      margin-right: ${Spacing.xs}px;
    }
  }
`;

export type ContentCardType = {
  redirectUrl: string;
  imageUrl: string;
  title: string;
  duration: number;
  type: CardContentEnum;
  id: string; // might be the content slug or something else ?
};

interface IContentCard extends ContentCardType {
  listIndex: number;
  cardIndex: number;
}

const ContentCard: React.FC<IContentCard> = ({
  redirectUrl,
  imageUrl,
  title,
  type,
  duration,
  id,
  listIndex,
  cardIndex
}) => {
  const history = useHistory();
  const handleClick = () => {
    trackContentClick(listIndex, cardIndex);

    if (type === CardContentEnum.Article) {
      setTimeout(() => {
        window.location.href = redirectUrl;
      }, 0);
    } else if (type === CardContentEnum.Video) {
      history.push(`/content/${id}`);
    }
  };

  return (
    <CardContainer onClick={handleClick}>
      <ImgContainer
        src={imageUrl || 'https://yolaw-production.s3.amazonaws.com/publicmedia/SASU_definition_caracteristiques.jpg'}
      >
        <TagsContainer>
          <Horizontal>
            <Chrono color="Primary.Regular" />
            {duration} mn
          </Horizontal>
          {type === CardContentEnum.Video && (
            <Horizontal>
              <Play />
              VIDÉO
            </Horizontal>
          )}
        </TagsContainer>
      </ImgContainer>
      <SmallLabel align="left">{title}</SmallLabel>
    </CardContainer>
  );
};

export default ContentCard;
