import React from 'react';

import { getColor } from '../../../helpers';

import { Colors } from '../../../styles/constants';

type ArrowProps = {
  color?: string;
  size?: number;
  rotation?: number;
};

const Arrow: React.FC<ArrowProps> = ({ color = 'Secondary.Regular', size = 16, rotation = 0 }) => (
  <svg width={size} height={size} viewBox="0 0 18 17" fill="none" style={{ transform: `rotate(${rotation}deg)` }}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.2816 12.7834L16.9604 9.10454C17.2728 8.79212 17.2728 8.28559 16.9604 7.97317L13.2816 4.29431C12.9691 3.98189 12.4626 3.98189 12.1502 4.29431C11.8378 4.60673 11.8378 5.11326 12.1502 5.42568L14.4634 7.73888H1.56001C1.11818 7.73888 0.76001 8.09705 0.76001 8.53888C0.76001 8.9807 1.11818 9.33888 1.56001 9.33888H14.4633L12.1502 11.652C11.8378 11.9644 11.8378 12.471 12.1502 12.7834C12.4626 13.0958 12.9691 13.0958 13.2816 12.7834Z"
      fill={getColor(Colors, color)}
    />
  </svg>
);

export default Arrow;
