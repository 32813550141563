declare const __ENV__: string;
declare global {
  interface Window {
    dataLayer?: object[];
  }
}

export const sendPageView = (path: string) => {
  const eventObj = {
    event: 'Pageview',
    pagePath: path,
    pageTitle: 'MyLS'
  };

  if (__ENV__ === 'production' && window.dataLayer) {
    window.dataLayer.push(eventObj);
  } else if (__ENV__ === 'production' && !window.dataLayer) {
    console.groupCollapsed('Google Analytics');
    console.error('[DEV] No GTM data layer existing');
    console.groupEnd();
  } else {
    console.groupCollapsed('Google Analytics');
    console.info('[DEV] Avoid sending dev data to Google Analytics');
    console.info('Would have sent an event as follows:', eventObj);
    console.groupEnd();
  }
};

const track = (action: string, label: string) => {
  const eventObj = {
    event: 'GAevent',
    eventCategory: 'MyLS',
    eventAction: action,
    eventLabel: label
  };

  if (__ENV__ === 'production' && window.dataLayer) {
    window.dataLayer.push(eventObj);
  } else if (__ENV__ === 'production' && !window.dataLayer) {
    console.groupCollapsed('Google Analytics');
    console.error('[DEV] No GTM data layer existing');
    console.groupEnd();
  } else {
    console.groupCollapsed('Google Analytics');
    console.info('[DEV] Avoid sending dev data to Google Analytics');
    console.info('Would have sent an event as follows:', eventObj);
    console.groupEnd();
  }
};

export default track;

// NEW UI TRACKING
export const trackSituationClick = (situationName: string) => track('clicked-CTA-situation', situationName);
export const trackActivitySelection = (activityName: string) => track('clicked-CTA-activity', activityName);
export const trackDelayClick = (delayLabel: string) => track('clicked-CTA-delay', delayLabel);
export const trackSubmitEmail = (email: string) => track('clicked-CTA-submit-email', email);
export const trackBookAppointment = () => track('clicked-CTA-expert', '');
export const trackContentClick = (sectionIndex: number, cardIndex: number) =>
  track('clicked-content', `${sectionIndex}-${cardIndex}`);
