import React, { useEffect, useState } from 'react';
import Lottie from 'react-lottie';
import styled from 'styled-components';
import { CSSTransition } from 'react-transition-group';
import { useHistory } from 'react-router-dom';

import * as animationData from './looping-hourglass.json';

import { Spacing } from '../../styles/constants';

import { Logo } from '../../components/Icon';
import { Body } from '../../components/Layout/Text';
import { Container, Content, Header } from '../../components/Layout/Page/V2';
import { Vertical } from '../../components/Layout/Containers';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData
};

const LottieContainer = styled.div`
  margin-bottom: ${Spacing.xl}px;
`;

const AnimationContainer = styled(Vertical)`
  align-items: center;
  display: none;
`;

const Processing = () => {
  const [animateIn, setAnimateIn] = useState(false);
  const [animateOut, setAnimateOut] = useState(true);
  const [speed, setSpeed] = useState(1);
  const history = useHistory();

  const toggleTransitionIn = () => setAnimateIn(!animateIn);
  const toggleTransitionOut = () => setAnimateOut(!animateOut);

  useEffect(() => {
    setSpeed(2);
    toggleTransitionIn();

    const redirectTimeout = setTimeout(() => {
      toggleTransitionOut();
      setTimeout(() => {
        history.push('/content');
      }, 800);
    }, 3000);

    return () => clearTimeout(redirectTimeout);
  }, []);

  return (
    <Container>
      <Header>
        <Logo />
      </Header>
      <CSSTransition classNames="stretch-out" timeout={200} appear in={animateOut}>
        <Content>
          <CSSTransition classNames="slide" timeout={200} appear in={animateIn}>
            <AnimationContainer>
              <LottieContainer>
                <Lottie
                  options={defaultOptions}
                  height={100}
                  width={100}
                  speed={speed}
                  isClickToPauseDisabled
                  ariaRole="img"
                />
              </LottieContainer>
              <Body>
                Un peu de patience, notre algorithme analyse vos réponses pour vous proposer un contenu adapté à vos
                besoins.
              </Body>
            </AnimationContainer>
          </CSSTransition>
        </Content>
      </CSSTransition>
    </Container>
  );
};

export default Processing;
