import React from 'react';

import { getColor } from '../../../helpers';

import { Colors } from '../../../styles/constants';

type CrossProps = {
  color?: string;
  size?: number;
};

const Cross: React.FC<CrossProps> = ({ color = 'Secondary.Regular', size = 12 }) => (
  <svg width={size} height={size} viewBox="0 0 8 8" fill="none">
    <path
      d="M4.70771 4.00008L7.85374 0.854052C8.0492 0.658759 8.0492 0.341887 7.85374 0.146595C7.65828 -0.0488649 7.34174 -0.0488649 7.14628 0.146595L4.00025 3.29263L0.854051 0.146595C0.658592 -0.0488649 0.342054 -0.0488649 0.146595 0.146595C-0.0488649 0.341887 -0.0488649 0.658759 0.146595 0.854052L3.29279 4.00008L0.146595 7.14612C-0.0488649 7.34141 -0.0488649 7.65828 0.146595 7.85357C0.244324 7.95114 0.372407 8 0.500323 8C0.628239 8 0.756322 7.95114 0.854051 7.8534L4.00025 4.70737L7.14628 7.8534C7.24401 7.95114 7.37209 8 7.50001 8C7.62792 8 7.75601 7.95114 7.85374 7.8534C8.0492 7.65811 8.0492 7.34124 7.85374 7.14595L4.70771 4.00008Z"
      fill={getColor(Colors, color)}
    />
  </svg>
);

export default Cross;
