import React from 'react';

type PlayProps = {
  size?: number;
};

const Play: React.FC<PlayProps> = ({ size = 12 }) => (
  <svg width={size} height={size} viewBox="0 0 10 10" fill="none">
    <path
      d="M5 10C2.23488 10 0 7.76584 0 5C0 2.23486 2.23418 0 5 0C7.76514 0 10 2.23416 10 5C10 7.76512 7.76584 10 5 10Z"
      fill="#16A974"
    />
    <path
      d="M3.24219 7.34375V2.65625C3.24219 2.5482 3.30166 2.44892 3.39691 2.39794C3.49217 2.34697 3.60777 2.35255 3.69766 2.41248L7.21328 4.75623C7.29479 4.81056 7.34375 4.90203 7.34375 5C7.34375 5.09796 7.29481 5.18943 7.21328 5.24376L3.69766 7.58751C3.50518 7.71585 3.24219 7.57955 3.24219 7.34375Z"
      fill="white"
    />
  </svg>
);

export default Play;
