import React from 'react';

type LogoProps = {};

const Logo: React.FC<LogoProps> = () => (
  <svg width="200" height="33" viewBox="0 0 200 33" fill="none" className="logo">
    <path
      d="M58.2398 24.7095V16.3274C58.2398 15.7952 58.0972 15.358 57.8121 15.0159C57.546 14.6547 57.0803 14.4742 56.4151 14.4742C55.8069 14.4742 55.2937 14.6262 54.8755 14.9303C54.4574 15.2344 54.1437 15.5291 53.9347 15.8142V24.7095H49.544V16.3274C49.544 15.7952 49.4015 15.358 49.1164 15.0159C48.8503 14.6547 48.3846 14.4742 47.7193 14.4742C47.1301 14.4742 46.6264 14.6262 46.2083 14.9303C45.8091 15.2344 45.4955 15.5291 45.2674 15.8142V24.7095H40.8768V10.9388H45.2674V12.621C45.4195 12.4309 45.6381 12.2218 45.9232 11.9937C46.2083 11.7466 46.5504 11.5186 46.9495 11.3095C47.3487 11.1004 47.7859 10.9293 48.261 10.7963C48.7362 10.6632 49.2494 10.5967 49.8006 10.5967C50.789 10.5967 51.6158 10.8058 52.281 11.2239C52.9463 11.6231 53.4215 12.2313 53.7066 13.0486C53.8966 12.7445 54.1532 12.4499 54.4764 12.1648C54.7995 11.8607 55.1606 11.5946 55.5598 11.3665C55.9779 11.1384 56.4246 10.9578 56.8998 10.8248C57.394 10.6727 57.9166 10.5967 58.4679 10.5967C59.7413 10.5967 60.7487 10.9293 61.49 11.5946C62.2313 12.2598 62.6019 13.3052 62.6019 14.7308V24.7095H58.2398Z"
      fill="#2D3C56"
    />
    <path
      d="M66.1657 26.1635C66.3178 26.2586 66.4889 26.3251 66.6789 26.3631C66.888 26.4011 67.0781 26.4201 67.2491 26.4201C67.7433 26.4201 68.1425 26.3631 68.4466 26.2491C68.7697 26.135 68.9883 25.9355 69.1023 25.6503L69.416 24.9091L63.9419 10.9388H68.5606L71.6683 19.6631L74.8045 10.9388H79.4517L73.2364 26.6767C72.9513 27.399 72.6091 27.9882 72.21 28.4444C71.8299 28.9196 71.3927 29.2902 70.8985 29.5563C70.4043 29.8224 69.8626 30.003 69.2734 30.098C68.6842 30.2121 68.0664 30.2786 67.4202 30.2976C67.3252 30.2976 67.1921 30.2881 67.0211 30.2691C66.85 30.2691 66.6694 30.2596 66.4794 30.2406C66.3083 30.2216 66.1372 30.193 65.9662 30.155C65.7951 30.136 65.6525 30.1075 65.5385 30.0695L66.1657 26.1635Z"
      fill="#2D3C56"
    />
    <path d="M81.3167 24.7095V5.69287H83.455V24.7095H81.3167Z" fill="#2D3C56" />
    <path
      d="M93.9358 25.0516C92.9094 25.0516 91.9686 24.8806 91.1132 24.5384C90.2579 24.1773 89.5166 23.6831 88.8894 23.0559C88.2812 22.4096 87.7965 21.6493 87.4354 20.775C87.0932 19.8817 86.9222 18.8933 86.9222 17.8099C86.9222 16.8215 87.0932 15.8902 87.4354 15.0159C87.7775 14.1225 88.2432 13.3527 88.8324 12.7065C89.4406 12.0603 90.1534 11.5471 90.9707 11.1669C91.807 10.7868 92.7193 10.5967 93.7077 10.5967C94.7531 10.5967 95.6845 10.7868 96.5018 11.1669C97.3191 11.5471 98.0128 12.0698 98.583 12.735C99.1533 13.4003 99.5809 14.1891 99.866 15.1014C100.17 15.9947 100.322 16.9546 100.322 17.981V18.5227H89.1745C89.2125 19.1689 89.3551 19.7867 89.6022 20.3759C89.8493 20.9461 90.1819 21.4498 90.6001 21.8869C91.0372 22.3051 91.5504 22.6472 92.1396 22.9133C92.7288 23.1604 93.3941 23.284 94.1354 23.284C94.9337 23.284 95.7035 23.1319 96.4447 22.8278C97.186 22.5237 97.8323 22.096 98.3835 21.5448L99.4098 22.9418C98.7256 23.6261 97.9273 24.1488 97.015 24.5099C96.1026 24.8711 95.0762 25.0516 93.9358 25.0516ZM98.2124 16.9546C98.2124 16.4414 98.1174 15.9187 97.9273 15.3865C97.7562 14.8353 97.4806 14.3411 97.1005 13.9039C96.7393 13.4478 96.2737 13.0771 95.7035 12.792C95.1333 12.5069 94.4585 12.3644 93.6792 12.3644C92.9379 12.3644 92.2917 12.5069 91.7405 12.792C91.1893 13.0771 90.7236 13.4383 90.3435 13.8754C89.9823 14.3126 89.6972 14.8068 89.4881 15.358C89.2981 15.8902 89.1935 16.4224 89.1745 16.9546H98.2124Z"
      fill="#2D3C56"
    />
    <path
      d="M109.317 30.2976C108.138 30.2976 107.121 30.155 106.266 29.8699C105.411 29.5848 104.574 29.0431 103.757 28.2448L104.84 26.6482C105.411 27.3325 106.047 27.8172 106.751 28.1023C107.473 28.3874 108.328 28.5299 109.317 28.5299C109.887 28.5299 110.438 28.4539 110.97 28.3018C111.502 28.1688 111.968 27.9312 112.367 27.5891C112.785 27.266 113.118 26.8383 113.365 26.3061C113.612 25.7929 113.736 25.1562 113.736 24.3959V22.4286C113.242 23.1319 112.586 23.7211 111.769 24.1963C110.97 24.6525 110.086 24.8806 109.117 24.8806C108.224 24.8806 107.397 24.719 106.637 24.3959C105.895 24.0728 105.249 23.6071 104.698 22.9989C104.166 22.3716 103.748 21.6208 103.443 20.7465C103.139 19.8722 102.987 18.8743 102.987 17.7529C102.987 16.6505 103.13 15.6621 103.415 14.7878C103.719 13.8944 104.137 13.1437 104.669 12.5354C105.221 11.9082 105.867 11.433 106.608 11.1099C107.368 10.7678 108.205 10.5967 109.117 10.5967C110.048 10.5967 110.913 10.8153 111.712 11.2525C112.51 11.6706 113.185 12.2598 113.736 13.0201V10.9388H115.874V24.3103C115.874 25.4508 115.684 26.4011 115.304 27.1614C114.943 27.9407 114.458 28.5584 113.85 29.0146C113.242 29.4708 112.538 29.7939 111.74 29.984C110.961 30.193 110.153 30.2976 109.317 30.2976ZM109.63 22.9989C110.048 22.9989 110.467 22.9418 110.885 22.8278C111.303 22.6947 111.693 22.5237 112.054 22.3146C112.415 22.1055 112.738 21.8679 113.023 21.6018C113.308 21.3357 113.546 21.0506 113.736 20.7465V14.7308C113.546 14.4266 113.308 14.1415 113.023 13.8754C112.738 13.6093 112.415 13.3813 112.054 13.1912C111.693 12.9821 111.303 12.8205 110.885 12.7065C110.467 12.5734 110.048 12.5069 109.63 12.5069C108.946 12.5069 108.328 12.64 107.777 12.9061C107.226 13.1532 106.76 13.5143 106.38 13.9895C106 14.4457 105.705 14.9969 105.496 15.6431C105.306 16.2893 105.211 16.9926 105.211 17.7529C105.211 18.5132 105.306 19.2164 105.496 19.8627C105.705 20.4899 106 21.0411 106.38 21.5163C106.76 21.9725 107.226 22.3336 107.777 22.5997C108.328 22.8658 108.946 22.9989 109.63 22.9989Z"
      fill="#2D3C56"
    />
    <path
      d="M128.767 24.7095V23.1414C127.608 24.4149 126.087 25.0516 124.205 25.0516C123.616 25.0516 123.037 24.9566 122.466 24.7665C121.896 24.5764 121.383 24.2913 120.927 23.9112C120.471 23.512 120.1 23.0274 119.815 22.4571C119.53 21.8869 119.387 21.2312 119.387 20.4899C119.387 19.7296 119.53 19.0644 119.815 18.4942C120.1 17.924 120.471 17.4488 120.927 17.0686C121.383 16.6885 121.896 16.4129 122.466 16.2418C123.037 16.0518 123.616 15.9567 124.205 15.9567C125.156 15.9567 126.011 16.1088 126.771 16.4129C127.551 16.717 128.216 17.1922 128.767 17.8384V15.358C128.767 14.4266 128.435 13.7139 127.769 13.2197C127.123 12.7065 126.296 12.4499 125.289 12.4499C123.673 12.4499 122.267 13.0866 121.069 14.3601L120.071 12.8776C121.535 11.357 123.369 10.5967 125.574 10.5967C126.296 10.5967 126.98 10.6822 127.627 10.8533C128.273 11.0244 128.834 11.3 129.309 11.6801C129.803 12.0412 130.193 12.5164 130.478 13.1056C130.763 13.6949 130.905 14.4076 130.905 15.2439V24.7095H128.767ZM124.975 23.512C125.736 23.512 126.448 23.3695 127.114 23.0844C127.798 22.7993 128.349 22.3716 128.767 21.8014V19.2069C128.349 18.6367 127.798 18.2091 127.114 17.924C126.448 17.6388 125.736 17.4963 124.975 17.4963C123.968 17.4963 123.151 17.7814 122.523 18.3516C121.896 18.9028 121.582 19.6251 121.582 20.5184C121.582 21.3928 121.896 22.115 122.523 22.6852C123.151 23.2364 123.968 23.512 124.975 23.512Z"
      fill="#2D3C56"
    />
    <path d="M135.192 24.7095V5.69287H137.33V24.7095H135.192Z" fill="#2D3C56" />
    <path
      d="M146.328 25.0516C145.72 25.0516 145.102 25.0041 144.475 24.9091C143.867 24.814 143.278 24.6715 142.708 24.4814C142.156 24.2913 141.634 24.0728 141.139 23.8257C140.664 23.5596 140.246 23.265 139.885 22.9418L141.71 19.9197C141.957 20.1478 142.27 20.3759 142.651 20.604C143.05 20.813 143.468 21.0031 143.905 21.1742C144.342 21.3452 144.789 21.4878 145.245 21.6018C145.72 21.6969 146.157 21.7444 146.556 21.7444C147.222 21.7444 147.697 21.6493 147.982 21.4593C148.267 21.2692 148.41 21.0126 148.41 20.6895C148.41 20.3854 148.21 20.1668 147.811 20.0337C147.412 19.9007 146.918 19.7867 146.328 19.6916C145.739 19.5776 145.093 19.454 144.39 19.321C143.705 19.1689 143.069 18.9408 142.479 18.6367C141.89 18.3136 141.396 17.8859 140.997 17.3537C140.598 16.8025 140.398 16.0613 140.398 15.1299C140.398 14.5217 140.522 13.942 140.769 13.3908C141.035 12.8395 141.415 12.3644 141.909 11.9652C142.403 11.5471 143.021 11.2144 143.762 10.9673C144.504 10.7203 145.35 10.5967 146.3 10.5967C147.459 10.5967 148.524 10.7678 149.493 11.1099C150.462 11.433 151.318 11.8702 152.059 12.4214L150.377 15.3295C149.959 14.9303 149.389 14.5787 148.666 14.2746C147.944 13.9705 147.165 13.8184 146.328 13.8184C145.815 13.8184 145.397 13.9135 145.074 14.1035C144.751 14.2936 144.589 14.5312 144.589 14.8163C144.589 15.0824 144.789 15.282 145.188 15.415C145.587 15.5291 146.081 15.6431 146.671 15.7571C147.26 15.8522 147.896 15.9757 148.581 16.1278C149.284 16.2798 149.93 16.5269 150.519 16.8691C151.109 17.1922 151.603 17.6483 152.002 18.2376C152.401 18.8078 152.601 19.5586 152.601 20.4899C152.601 21.1552 152.458 21.7634 152.173 22.3146C151.888 22.8658 151.479 23.3505 150.947 23.7686C150.415 24.1678 149.759 24.4814 148.98 24.7095C148.201 24.9376 147.317 25.0516 146.328 25.0516Z"
      fill="#2D3C56"
    />
    <path
      d="M160.081 25.0516C158.675 25.0516 157.601 24.7095 156.86 24.0252C156.137 23.341 155.776 22.3336 155.776 21.0031V14.7593H153.495V10.9388H155.776V7.17543H160.138V10.9388H162.932V14.7593H160.138V19.7771C160.138 20.1763 160.243 20.5089 160.452 20.775C160.68 21.0411 160.984 21.1742 161.364 21.1742C161.611 21.1742 161.839 21.1457 162.048 21.0886C162.258 21.0126 162.4 20.9271 162.476 20.832L163.331 24.1393C163.065 24.4054 162.657 24.624 162.105 24.795C161.573 24.9661 160.899 25.0516 160.081 25.0516Z"
      fill="#2D3C56"
    />
    <path
      d="M173.263 24.7095V23.341C172.826 23.8542 172.227 24.2723 171.467 24.5955C170.707 24.8996 169.88 25.0516 168.987 25.0516C168.454 25.0516 167.903 24.9566 167.333 24.7665C166.782 24.5954 166.278 24.3294 165.822 23.9682C165.366 23.5881 164.986 23.1129 164.681 22.5427C164.396 21.9725 164.254 21.2977 164.254 20.5184C164.254 19.6821 164.396 18.9883 164.681 18.4371C164.986 17.8669 165.366 17.4108 165.822 17.0686C166.278 16.7265 166.782 16.4794 167.333 16.3274C167.903 16.1753 168.454 16.0993 168.987 16.0993C169.899 16.0993 170.735 16.2418 171.496 16.5269C172.256 16.793 172.845 17.1827 173.263 17.6959V16.0422C173.263 15.434 173.016 14.9493 172.522 14.5882C172.047 14.2271 171.362 14.0465 170.469 14.0465C169.785 14.0465 169.101 14.17 168.416 14.4171C167.732 14.6642 167.114 15.0254 166.563 15.5005L164.995 12.735C165.888 11.9747 166.886 11.433 167.989 11.1099C169.091 10.7678 170.175 10.5967 171.239 10.5967C172.056 10.5967 172.855 10.6822 173.634 10.8533C174.413 11.0244 175.097 11.319 175.687 11.7371C176.276 12.1553 176.751 12.7255 177.112 13.4478C177.473 14.151 177.654 15.0349 177.654 16.0993V24.7095H173.263ZM170.811 22.172C171.305 22.172 171.781 22.0865 172.237 21.9154C172.693 21.7444 173.035 21.4973 173.263 21.1742V19.9482C173.035 19.6251 172.693 19.3875 172.237 19.2354C171.781 19.0644 171.305 18.9788 170.811 18.9788C170.222 18.9788 169.709 19.1119 169.272 19.378C168.835 19.6251 168.616 20.0242 168.616 20.5754C168.616 21.1267 168.835 21.5353 169.272 21.8014C169.709 22.0485 170.222 22.172 170.811 22.172Z"
      fill="#2D3C56"
    />
    <path
      d="M180.841 24.7095V10.9388H185.231V12.621C185.46 12.3359 185.735 12.0698 186.058 11.8227C186.381 11.5756 186.733 11.3665 187.113 11.1954C187.493 11.0054 187.883 10.8533 188.282 10.7393C188.7 10.6252 189.118 10.5682 189.537 10.5682V14.7878C189.403 14.7498 189.242 14.7213 189.052 14.7022C188.881 14.6832 188.681 14.6737 188.453 14.6737C188.168 14.6737 187.864 14.7022 187.541 14.7593C187.237 14.8163 186.933 14.9018 186.628 15.0159C186.324 15.1299 186.049 15.263 185.802 15.415C185.555 15.5671 185.364 15.7381 185.231 15.9282V24.7095H180.841Z"
      fill="#2D3C56"
    />
    <path
      d="M196.75 25.0516C195.343 25.0516 194.269 24.7095 193.528 24.0252C192.806 23.341 192.445 22.3336 192.445 21.0031V14.7593H190.164V10.9388H192.445V7.17543H196.807V10.9388H199.601V14.7593H196.807V19.7771C196.807 20.1763 196.911 20.5089 197.12 20.775C197.348 21.0411 197.653 21.1742 198.033 21.1742C198.28 21.1742 198.508 21.1457 198.717 21.0886C198.926 21.0126 199.069 20.9271 199.145 20.832L200 24.1393C199.734 24.4054 199.325 24.624 198.774 24.795C198.242 24.9661 197.567 25.0516 196.75 25.0516Z"
      fill="#2D3C56"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.839849 12.2295L8.81842 13.9648V18.2132L0.839849 19.9485C0.77986 19.9485 0.719871 19.9485 0.659882 19.9485C0.299946 19.9485 0 19.6493 0 19.2903V12.9475C0 12.5885 0.299946 12.2893 0.659882 12.2893C0.719871 12.2295 0.77986 12.2295 0.839849 12.2295Z"
      fill="#16A974"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6368 7.86121V12.1097L8.81842 14.0244V6.84398C8.81842 6.66447 8.87841 6.48496 8.99839 6.36528C9.17836 6.18577 9.41831 6.12593 9.65827 6.18577L17.6368 7.86121Z"
      fill="#16A974"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.6368 24.3166V20.0681L8.81842 18.1533V25.3338C8.81842 25.5133 8.87841 25.6928 8.99839 25.8125C9.17836 25.992 9.41831 26.0518 9.65827 25.992L17.6368 24.3166Z"
      fill="#16A974"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.3953 2.29625V5.40779C26.3953 5.70697 26.1553 6.00616 25.8554 6.066L17.5768 7.86111V0.680648C17.5768 0.501137 17.6368 0.321625 17.7568 0.201951C17.9368 0.022439 18.1767 -0.0373983 18.4167 0.022439L25.8554 1.63804C26.2153 1.63804 26.3953 1.93723 26.3953 2.29625Z"
      fill="#16A974"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.3953 29.881V26.7695C26.3953 26.4703 26.1553 26.1711 25.8554 26.1113L17.5768 24.3162V31.4966C17.5768 31.6761 17.6368 31.8557 17.7568 31.9753C17.9368 32.1548 18.1767 32.2147 18.4167 32.1548L25.8554 30.5392C26.2153 30.5392 26.3953 30.24 26.3953 29.881Z"
      fill="#16A974"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.3953 14.563V17.6745C26.3953 17.9737 26.1553 18.2729 25.8554 18.3327L17.5768 20.1278V12.0498L25.8554 13.8449C26.2153 13.9048 26.3953 14.2039 26.3953 14.563Z"
      fill="#16A974"
    />
  </svg>
);

export default Logo;
