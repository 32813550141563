import styled, { css } from 'styled-components';

import { Colors, Font, Spacing } from '../../../styles/constants';

type TextCommonType = {
  align?: string;
};

const TextCommon = css<TextCommonType>`
  line-height: 1.5;
  font-weight: ${Font.weight.regular};
  color: ${Colors.Secondary.Regular};
  margin: ${Spacing.m}px 0;
  text-align: ${({ align }) => (align ? align : 'center')};

  & + * {
    margin-top: ${Spacing.s}px;
  }
`;

export const Intro = styled.p`
  ${TextCommon};
  font-size: ${Font.sizes.l};
`;

export const Body = styled.p`
  ${TextCommon};
  font-size: ${Font.sizes.m};
`;

export const Label = styled.p`
  ${TextCommon};
  margin: ${Spacing.s}px 0;
  font-size: ${Font.sizes.l};
  font-weight: ${Font.weight.bold};
`;

export const SmallLabel = styled(Label)`
  font-size: ${Font.sizes.s};
`;
