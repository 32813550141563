import React from 'react';

import { Arrow, Logo, WavingHand } from '../../components/Icon';
import { MainTitle } from '../../components/Layout/Title';
import { Intro } from '../../components/Layout/Text';
import { Container, Content, Header } from '../../components/Layout/Page/V2';
import StyledLink from '../../components/Layout/Link';

const WelcomeView: React.FC<{}> = () => {
  return (
    <Container>
      <Header>
        <Logo />
      </Header>
      <Content>
        <WavingHand size={80} />
        <MainTitle>Bienvenue sur MyLegalstart</MainTitle>
        <Intro>La plateforme qui vous accompagne dans la préparation de votre projet</Intro>
        <StyledLink to="/project">
          C’est parti ! <Arrow color="White.Regular" size={20} />
        </StyledLink>
      </Content>
    </Container>
  );
};

export default WelcomeView;
